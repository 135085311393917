import React from "react";
import Button from "src/components/Button";
import AccessControlIcon from "src/layouts/dashboard/Icons/AccessControlIcon";

const index = () => {
  return (
    <div className="w-full h-[100%] flex justify-center items-center">
      <div className="w-[270px] h-auto flex flex-col items-center gap-[30px] py-[50px]">
        <AccessControlIcon />
        {/* <Button
          type="button"
          label="coming soon"
          style={{
            borderRadius: "25px",
            width: "153px",
            margin: "auto",
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          COMING SOON
        </Button> */}
        <p className="text-center text-[#707070]">
          We’ll be showing all your guests activity here, coming very soon
        </p>
      </div>
    </div>
  );
};

export default index;
