import React from "react";
import Button from "src/components/Button";

const PassImageItem = () => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-[10px]">
        <img
          src="/android-chrome-192x192.png"
          alt=""
          className="w-[64px] h-[64px] object-cover rounded-[5px]"
        />
        <p>Image 1</p>
      </div>
      <Button
        label="Set Image"
        type="button"
        className="whitespace-nowrap !text-[12px]  sm:!text-[14px]"
        style={{
          height: "35px",
          width: "60px",
          fontWeight: 400,
          fontSize: 13,
        }}
      >
        Select
      </Button>
    </div>
  );
};

const SelectPassImageModal = () => {
  return (
    <div className="w-full lg:w-[500px] h-[90vh] rounded-[5px] bg-white py-[30px] lg:px-[30px] px-6  flex flex-col gap-[40px]">
      <h2 className="text-[24px] font-medium">Select a pass image</h2>
      <div className="bg-[#FAFAFA] border border-[#F2F2F2] w-full h-[480px] overflow-auto rounded-[10px] py-[30px] px-[12px] flex flex-col gap-[20px]">
        {[1, 2, 3, 4, 5].map((item, index) => (
          <PassImageItem />
        ))}
      </div>
      <div className="flex items-center justify-end gap-[20px]">
        <Button
          label="Set Image"
          type="button"
          design="secondary"
          className="whitespace-nowrap !text-[12px]  sm:!text-[14px]"
          style={{ height: "40px" }}
        >
          Cancel
        </Button>
        <Button
          label="Set Image"
          type="button"
          className="whitespace-nowrap !text-[12px]  sm:!text-[14px]"
          style={{ height: "40px" }}
        >
          Set this image
        </Button>
      </div>
    </div>
  );
};

export default SelectPassImageModal;
