import React from "react";

const EventSchedule = () => {
  return (
    <svg
      width="282"
      height="84"
      viewBox="0 0 282 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="49.1562"
        y="25.4189"
        width="58.8289"
        height="24.2989"
        rx="12.1494"
        fill="url(#paint0_linear_927_13795)"
      />
      <rect
        x="49.1562"
        y="25.4189"
        width="58.8289"
        height="24.2989"
        rx="12.1494"
        fill="black"
        fill-opacity="0.1"
      />
      <rect
        x="171.93"
        y="25.4189"
        width="62.6655"
        height="24.2989"
        rx="12.1494"
        fill="url(#paint1_linear_927_13795)"
      />
      <rect
        x="171.93"
        y="25.4189"
        width="62.6655"
        height="24.2989"
        rx="12.1494"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M99.2715 26.1481C99.2715 12.0219 110.723 0.570312 124.849 0.570312H157.423C171.549 0.570312 183.001 12.0219 183.001 26.1481V41.7314C183.001 64.8527 164.257 83.5961 141.136 83.5961C118.015 83.5961 99.2715 64.8526 99.2715 41.7314V26.1481Z"
        fill="url(#paint2_linear_927_13795)"
      />
      <path
        d="M99.2715 26.1481C99.2715 12.0219 110.723 0.570312 124.849 0.570312H157.423C171.549 0.570312 183.001 12.0219 183.001 26.1481V41.7314C183.001 64.8527 164.257 83.5961 141.136 83.5961C118.015 83.5961 99.2715 64.8526 99.2715 41.7314V26.1481Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M0.972656 28.2604C0.972656 14.1342 12.4242 2.68262 26.5504 2.68262H34.498C48.6242 2.68262 60.0758 14.1342 60.0758 28.2604V47.0099C60.0758 63.3308 46.8451 76.5615 30.5242 76.5615C14.2033 76.5615 0.972656 63.3308 0.972656 47.01V28.2604Z"
        fill="url(#paint3_linear_927_13795)"
      />
      <path
        d="M0.972656 28.2604C0.972656 14.1342 12.4242 2.68262 26.5504 2.68262H34.498C48.6242 2.68262 60.0758 14.1342 60.0758 28.2604V47.0099C60.0758 63.3308 46.8451 76.5615 30.5242 76.5615C14.2033 76.5615 0.972656 63.3308 0.972656 47.01V28.2604Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M222.896 28.2604C222.896 14.1342 234.348 2.68262 248.474 2.68262H256.422C270.548 2.68262 282 14.1342 282 28.2604V47.0099C282 63.3308 268.769 76.5615 252.448 76.5615C236.127 76.5615 222.896 63.3308 222.896 47.01V28.2604Z"
        fill="url(#paint4_linear_927_13795)"
      />
      <path
        d="M222.896 28.2604C222.896 14.1342 234.348 2.68262 248.474 2.68262H256.422C270.548 2.68262 282 14.1342 282 28.2604V47.0099C282 63.3308 268.769 76.5615 252.448 76.5615C236.127 76.5615 222.896 63.3308 222.896 47.01V28.2604Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M161.272 42.1131L151.122 53.9306C149.149 56.2614 146.242 57.5942 143.188 57.568C140.134 57.5418 137.249 56.1593 135.316 53.795L125.792 42.1182C124.087 40.0363 123.232 37.3852 123.398 34.6992C123.564 32.0123 124.74 29.4882 126.69 27.6328C128.799 25.7224 131.609 24.7799 134.442 25.0323C137.288 25.2644 139.902 26.6913 141.636 28.9588L143.625 31.3722L145.343 29.3381C147.033 27.2436 149.455 25.8679 152.12 25.4888C154.784 25.1108 157.493 25.7562 159.699 27.2969C161.875 29.0548 163.247 31.6182 163.503 34.4029C163.844 37.1699 163.038 39.9562 161.273 42.1131L161.272 42.1131Z"
        fill="url(#paint5_linear_927_13795)"
      />
      <path
        opacity="0.6"
        d="M266.179 39.0436L259.086 47.3012C257.708 48.9299 255.676 49.8612 253.542 49.8429C251.408 49.8246 249.392 48.8586 248.042 47.2065L241.387 39.0472C240.195 37.5924 239.598 35.74 239.713 33.8632C239.83 31.9857 240.651 30.2219 242.014 28.9254C243.488 27.5905 245.451 26.9319 247.431 27.1083C249.419 27.2705 251.246 28.2675 252.458 29.852L253.848 31.5383L255.048 30.117C256.229 28.6535 257.921 27.6922 259.783 27.4272C261.645 27.1631 263.538 27.6141 265.08 28.6907C266.6 29.919 267.558 31.7103 267.738 33.6561C267.976 35.5896 267.413 37.5365 266.179 39.0436L266.179 39.0436Z"
        fill="url(#paint6_linear_927_13795)"
      />
      <path
        opacity="0.6"
        d="M42.3721 40.3229L35.2795 48.5805C33.901 50.2092 31.8693 51.1405 29.7352 51.1222C27.6011 51.1039 25.5858 50.1379 24.2349 48.4858L17.5802 40.3265C16.3882 38.8717 15.791 37.0193 15.9069 35.1425C16.0233 33.265 16.8448 31.5012 18.2076 30.2047C19.6812 28.8698 21.6447 28.2112 23.6241 28.3876C25.6125 28.5498 27.439 29.5468 28.651 31.1313L30.041 32.8176L31.2413 31.3963C32.4224 29.9328 34.1146 28.9715 35.9764 28.7065C37.8383 28.4424 39.7313 28.8934 41.273 29.97C42.793 31.1983 43.7516 32.9896 43.9309 34.9354C44.1693 36.8689 43.606 38.8158 42.3723 40.3229L42.3721 40.3229Z"
        fill="url(#paint7_linear_927_13795)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_927_13795"
          x1="61.243"
          y1="25.4189"
          x2="75.7371"
          y2="64.7329"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#026051" />
          <stop offset="1" stop-color="#3B5F59" stop-opacity="0" />
          <stop offset="1" stop-color="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_927_13795"
          x1="184.805"
          y1="25.4189"
          x2="198.607"
          y2="65.2994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#026051" />
          <stop offset="1" stop-color="#3B5F59" stop-opacity="0" />
          <stop offset="1" stop-color="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_927_13795"
          x1="116.474"
          y1="0.570313"
          x2="192.203"
          y2="86.1325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#026051" />
          <stop offset="1" stop-color="#3B5F59" stop-opacity="0" />
          <stop offset="1" stop-color="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_927_13795"
          x1="13.1158"
          y1="2.68262"
          x2="80.5997"
          y2="63.1671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#026051" />
          <stop offset="1" stop-color="#3B5F59" stop-opacity="0" />
          <stop offset="1" stop-color="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_927_13795"
          x1="235.04"
          y1="2.68262"
          x2="302.524"
          y2="63.1671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#026051" />
          <stop offset="1" stop-color="#3B5F59" stop-opacity="0" />
          <stop offset="1" stop-color="#47A092" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_927_13795"
          x1="143.568"
          y1="25.0778"
          x2="143.289"
          y2="57.5689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A2C3BE" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_927_13795"
          x1="253.808"
          y1="27.1401"
          x2="253.613"
          y2="49.8435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A2C3BE" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_927_13795"
          x1="30.0011"
          y1="28.4194"
          x2="29.8063"
          y2="51.1228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A2C3BE" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { EventSchedule };
