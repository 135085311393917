import { useState } from "react";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import Info from "src/components/Info";
// import AddPart from "../modals/AddPart";
import styles from "./styles.module.scss";
import AccessControlIcon from "src/layouts/dashboard/Icons/AccessControlIcon";
import ToggleAccessControlModal from "../Modals/ToggleAccessControlModal";

function EmptyState() {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles["EmptyState"]}>
      <Info>
        {/* <Icon iconName="bulb" /> */}
        <p className={styles["EmptyState--title"]}>
          Access control allows you to make your event fully private
        </p>
      </Info>

      <div className={styles["EmptyState--main"]}>
        {/* <Icon iconName="parts" /> */}
        <div className="lg:mb-[50px] mb-[30px]">
          <AccessControlIcon />
        </div>
        <p>Activate access control if you want a totally private event</p>
        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <div
              onClick={() => setOpen(true)}
              className={styles["EmptyState--main__trigger"]}
            >
              <span>Activate for this event</span>
            </div>
          }
        >
          <ToggleAccessControlModal setOpen={setOpen} />
        </DialogModal>
      </div>
    </div>
  );
}

export default EmptyState;
