import React from "react";

const PlusIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.186523"
        y="0.0449219"
        width="26.0749"
        height="26.0749"
        rx="6.53129"
        fill="url(#paint0_linear_1021_15978)"
      />
      <rect
        x="0.186523"
        y="0.0449219"
        width="26.0749"
        height="26.0749"
        rx="6.53129"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M12.1963 6.57605H14.2509V19.5883H12.1963V6.57605Z"
        fill="white"
      />
      <path
        d="M6.71729 12.0549H19.7296V14.1095H6.71729V12.0549Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1021_15978"
          x1="5.54377"
          y1="0.0449219"
          x2="29.3507"
          y2="26.717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#026051" />
          <stop offset="1" stop-color="#3B5F59" stop-opacity="0" />
          <stop offset="1" stop-color="#47A092" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlusIcon;
