import { useState } from "react";
import Icon from "src/components/Icon";
import { eventParts_eventParts } from "src/types/api";
import styles from "./styles.module.scss";
import * as Popover from "@radix-ui/react-popover";
import DialogModal from "src/components/Dialog";
import EditPart from "src/pages/event-details/MyParts/modals/EditPart";
import RemovePart from "src/pages/event-details/MyParts/modals/RemovePart";
import { formatDate, getUtcOffset } from "src/utils";

interface Props {
  part: eventParts_eventParts;
}

function EventPartCard({ part }: Props) {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <div className={styles["EventPartCard"]}>
      <header className={styles["EventPartCard__header"]}>
        <p className={styles["EventPartCard__header--title"]}>{part.name}</p>

        <Popover.Root>
          <Popover.Trigger
            className={styles["EventPartCard__dropdown-container-button"]}
          >
            <Icon iconName="kebab" />
            <span className="visually-hidden">open dropdown</span>
          </Popover.Trigger>
          <Popover.Content className={styles["EventPartCard__dropdown"]}>
            <div className={styles["EventPartCard__dropdown-list"]}>
              {!!part && (
                <>
                  <DialogModal
                    trigger={
                      <div className={styles["EventPartCard__edit"]}>
                        Edit Part
                      </div>
                    }
                    open={openEdit}
                    onOpenChange={setOpenEdit}
                  >
                    <EditPart setOpen={setOpenEdit} part={part} />
                  </DialogModal>
                  <DialogModal
                    trigger={
                      <div className={styles["EventPartCard__remove"]}>
                        Delete Part
                      </div>
                    }
                    open={openDelete}
                    onOpenChange={setOpenDelete}
                  >
                    <RemovePart setOpen={setOpenDelete} part={part} />
                  </DialogModal>
                </>
              )}
            </div>
          </Popover.Content>
        </Popover.Root>
      </header>
      <main className={styles["EventPartCard__main"]}>
        <ul>
          <li>
            <Icon iconName="location" />
            <p>{part.address}</p>
          </li>
          <li>
            <Icon iconName="calendar" />
            <p>{formatDate(part?.time)}</p>
          </li>
          <li>
            <Icon iconName="clock" />
            <p>
              {getUtcOffset(part?.time as unknown as Date).format("hh:mm A")}
            </p>
          </li>
        </ul>
      </main>
    </div>
  );
}

export { EventPartCard };
