import React from "react";
import Header from "./components/Header";
import PassCard from "./components/PassCard";
import DialogModal from "src/components/Dialog";
import ComingSoonModal from "../Modals/ComingSoonModal";
import PassDesign from "src/layouts/dashboard/Icons/PassDesign";
import PlusIcon from "src/layouts/dashboard/Icons/PlusIcon";

const Index = () => {
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <div className="w-full py-[30px] flex flex-col gap-[27px]">
      <Header />
      <div className="flex items-center gap-[37px] flex-wrap justify-center lg:justify-start">
        <PassCard />
        <DialogModal
          open={openModal}
          onOpenChange={setOpenModal}
          trigger={
            <div className="w-[307px] h-[437px] rounded-[18px] border border-dashed border-[#9F9F9F] flex items-center justify-center flex-col gap-[20px] px-[50px]">
              <div className="flex flex-col items-center">
                <div className="mb-[12px]">
                  <PassDesign />
                </div>
                <div className="mb-[26px]">
                  <PlusIcon />
                </div>
                <h3 className="mb-[15px] text-[#919191] text-[12px] tracking-[1px] font-semibold uppercase">
                  See More pass designs
                </h3>
                <p className="text-center text-[#898989] text-[13px]">
                  See more pass designs that you can use for your wedding to
                  make it unique
                </p>
              </div>
            </div>
          }
        >
          <ComingSoonModal />
        </DialogModal>
      </div>
    </div>
  );
};

export default Index;
