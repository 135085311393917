export function getTimestamp(date: any, time: any) {
  // Extract year, month, and day from the date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  // Extract hours and minutes from the time string
  const [hours, minutes] = time.split(":");

  // Create a new Date object with the combined values
  const combinedDateTime = new Date(
    `${year}-${month}-${day}T${hours}:${minutes}:00Z`
  );

  // Return the Unix timestamp in seconds
  return Math.floor(combinedDateTime.getTime() / 1000);
}
