import Button from "src/components/Button";
import styles from "./styles.module.scss";
import { Dispatch, SetStateAction } from "react";
import { deleteEventPart, eventParts_eventParts } from "src/types/api";
import { useMutation } from "@apollo/client";
import { DeleteEventPart } from "src/graphql/mutations";
import toast from "react-hot-toast";
import { EventParts } from "src/graphql/queries";
import useAppStore from "src/store/utils";

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  part: eventParts_eventParts;
}

function RemovePart({ setOpen, part }: Props) {
  const { event } = useAppStore((state) => state);

  const [deleteEventPartFn, { loading }] = useMutation<deleteEventPart>(
    DeleteEventPart,
    {
      onCompleted() {
        setOpen(false);
        toast.success(
          <p className="toast">{part.name} has been deleted from your event.</p>
        );
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        }
        setOpen(false);
      },
    }
  );
  return (
    <div className={styles["RemovePart"]}>
      <h1>Remove Part</h1>
      <p>You are about to remove this event part . Do you wish to proceed?</p>
      <div className={styles["RemovePart--btn"]}>
        <Button
          loader={loading}
          type="submit"
          label="remove part"
          handleClick={() =>
            deleteEventPartFn({
              variables: {
                eventId: event.id,
                partId: part.id,
              },
              refetchQueries: [
                { query: EventParts, variables: { eventId: event.id } },
              ],
            })
          }
        >
          Yes, Delete
        </Button>
      </div>
    </div>
  );
}

export default RemovePart;
