import React from "react";
import Button from "src/components/Button";
import { ActivateAccessControlIcon } from "src/layouts/dashboard/Icons/AccessControlIcon";

const ToggleAccessControlModal = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  return (
    <div className="w-[500px] h-auto bg-white rounded-[5px] py-[35px] px-[45px] flex flex-col items-center gap-[30px]">
      <p className="text-center text-[20px] text-[#707070]">
        Are you sure you want to make this event totally private?
      </p>
      <ActivateAccessControlIcon />
      <div className="flex items-center justify-between w-full gap-[18px]">
        <Button label="Yes, Activate" type="button" handleClick={() => {}}>
          Yes, Activate
        </Button>
        <Button
          label="No, Later"
          type="button"
          design="secondary"
          className="whitespace-nowrap !text-[12px]  sm:!text-[14px]"
          handleClick={() => setOpen(false)}
        >
          No, Later
        </Button>
      </div>
    </div>
  );
};

export default ToggleAccessControlModal;
