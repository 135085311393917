import { useQuery } from "@apollo/client";
import {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { InvitationListByRSVP } from "src/graphql/queries";
import styles from "./notComing.module.scss";
import InnerLoading from "src/components/InnerLoading";
// import Pagination from "src/components/Pagination";
import { AppContext, ValueProps } from "src/context";
import EmptyState from "./EmptyState";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { invitationListByRSVPStatus } from "src/types/api";
import { SearchInput } from "src/components/Input";
import Icon from "src/components/Icon";
import Row from "./Row";
import { useNavigate } from "react-router-dom";
import { SectionRsvpCountType } from "src/utils/types";
import useAppStore from "src/store/utils";
import useCustomQuery from "src/hooks/useCustomQuery";

type Props = {
  count: SectionRsvpCountType;
  setCount: Dispatch<SetStateAction<SectionRsvpCountType>>;
};

const NotComing: React.FC<Props> = (props) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [startPage, setStartPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (totalPages < 6) {
      setPageSize(totalPages);
    } else {
      setPageSize(6);
    }
  }, [pageSize, totalPages, setPageSize]);

  const { data: notComing, isLoading: notComingLoading } =
    useCustomQuery<invitationListByRSVPStatus>(
      InvitationListByRSVP,
      {
        eventId: event.id,
        rsvpStatus: "no",
        page: page,
        pageSize: pageSize,
      },
      !event.id
    );

  const results = !!notComing
    ? notComing.invitationListByRSVPStatus?.filter(
        (guest) =>
          guest.first_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          guest.last_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
      )
    : [];

  return (
    <>
      <div className={styles["notComing"]}>
        {notComingLoading ? (
          <div className={styles["notComing__loading"]}>
            <InnerLoading />
          </div>
        ) : !!notComing && !!notComing.invitationListByRSVPStatus ? (
          <div>
            <div className={styles["search"]}>
              <div className={styles["search__inner"]}>
                <SearchInput
                  icon={<Icon iconName="search" />}
                  name="Search names, emails etc."
                  placeholder="Search names, emails etc."
                />
              </div>
              <div className={styles["count"]}>
                <Icon iconName="twoUsers" />{" "}
                {notComing.invitationListByRSVPStatus.length}
              </div>
            </div>
            <div className={styles["notComing__table-list"]}>
              <div className={styles["notComing__table-header"]}>
                <h4>NAME</h4>
                <h4>EMAIL</h4>
                <h4>WHATSAPP NUMBER</h4>
                <h4>CATEGORY</h4>
              </div>
              {results?.map((data, _index) => (
                <>
                  <Row
                    key={uuidv4()}
                    id={data?.id}
                    firstName={data?.first_name}
                    lastName={data?.last_name}
                    phoneNumber={data?.phone}
                    email={data?.email ?? ""}
                    category={data?.category?.name ?? ""}
                  />
                </>
              ))}
            </div>
          </div>
        ) : (
          <EmptyState />
        )}
        {/*!!notComing && !!notComing.invitationListByRSVPStatus && (
          <Pagination
            pageSize={pageSize}
            startPage={startPage}
            setPageSize={setPageSize}
            setStartPage={setStartPage}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
          />
						)*/}
      </div>
    </>
  );
};

export default NotComing;
