import React from "react";
import DashboardLayout from "src/layouts/dashboard";
import EmptyState from "./EmptyState";
import styles from "./styles.module.scss";
import Tab from "src/components/Tab";
import { useState } from "react";
import { AccessControlTabType } from "src/utils/types";
import YourPass from "./YourPass";
import CheckInActivity from "./CheckInActivity";

const tabItems: AccessControlTabType[] = ["yourPass", "checkInActivity"];

const Index = () => {
  const [tabState, setTabState] = useState<AccessControlTabType>("yourPass");
  const handleTab = (element: AccessControlTabType) => {
    setTabState(element);
  };

  const tab: Record<AccessControlTabType, JSX.Element> = {
    yourPass: <YourPass />,
    checkInActivity: <CheckInActivity />,
  };
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  return (
    <DashboardLayout pageName="Access control">
      <>
        {isEmpty ? (
          <div className={styles["MyParts"]}>
            <EmptyState />
          </div>
        ) : (
          <div className={styles["invitations"]}>
            <div className={styles["invitations__tabs"]}>
              <Tab
                tabItems={tabItems}
                tabState={tabState}
                handleTab={handleTab}
              />
            </div>
            <div className={styles["invitations__main"]}>{tab[tabState]}</div>
          </div>
        )}
      </>
    </DashboardLayout>
  );
};

export default Index;
