/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signupByPhoneNumber
// ====================================================

export interface signupByPhoneNumber {
  signupByPhoneNumber: boolean | null;
}

export interface signupByPhoneNumberVariables {
  input: SignUpRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: loginUser
// ====================================================

export interface loginUser_login_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
}

export interface loginUser_login {
  __typename: "AuthResponse";
  access_expiry: any | null;
  access_token: string;
  refresh_expiry: any | null;
  refresh_token: string;
  user: loginUser_login_user | null;
}

export interface loginUser {
  login: loginUser_login;
}

export interface loginUserVariables {
  input: LoginRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyPhone
// ====================================================

export interface verifyPhone_verifyPhone_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  id: number;
  /**
   * last name of the person
   */
  last_name: string | null;
}

export interface verifyPhone_verifyPhone {
  __typename: "AuthResponse";
  access_expiry: any | null;
  access_token: string;
  refresh_expiry: any | null;
  refresh_token: string;
  user: verifyPhone_verifyPhone_user | null;
}

export interface verifyPhone {
  verifyPhone: verifyPhone_verifyPhone;
}

export interface verifyPhoneVariables {
  otp: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendForgetPassword
// ====================================================

export interface sendForgetPassword {
  sendForgetPasswordOTP: string;
}

export interface sendForgetPasswordVariables {
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resendVerifyPhoneOTP
// ====================================================

export interface resendVerifyPhoneOTP {
  resendVerifyPhoneOTP: boolean | null;
}

export interface resendVerifyPhoneOTPVariables {
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addEventPart
// ====================================================

export interface addEventPart_addEventPart {
  __typename: "EventPart";
  id: number;
  event_id: number;
  time: any;
  part: string;
  name: string;
  address: string;
  geo_position: {
    geo_position_id: string;
    latitude: number;
    longitude: number;
  };
}

export interface addEventPart {
  addEventPart: addEventPart_addEventPart;
}

export interface addEventPartVariables {
  input: AddEventPartRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEventPart
// ====================================================

export interface updateEventPart_updateEventPart {
  __typename: "EventPart";
  id: number;
  event_id: number;
  time: any;
  part: string;
  name: string;
  address: string;
  geo_position: {
    geo_position_id: string;
    latitude: number;
    longitude: number;
  };
}

export interface updateEventPart {
  updateEventPart: updateEventPart_updateEventPart;
}

export interface updateEventPartVariables {
  input: UpdateEventPartRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteEventPart
// ====================================================

export interface deleteEventPart {
  deleteEventPart: string;
}

export interface deleteEventPartVariables {
  eventId: number;
  partId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addEventVendor
// ====================================================

export interface addEventVendor_addEventVendor {
  __typename: "EventVendor";
  id: number;
  instagram_handle: string | null;
  event_id: number;
  role: string | null;
  email: string | null;
  name: string | null;
  phone_number: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface addEventVendor {
  addEventVendor: addEventVendor_addEventVendor | null;
}

export interface addEventVendorVariables {
  input: AddEventVendorRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEventVendor
// ====================================================

export interface updateEventVendor_updateEventVendor {
  __typename: "EventVendor";
  id: number;
  image: string | null;
  instagram_handle: string | null;
  name: string | null;
  role: string | null;
  event_id: number;
  phone_number: string;
}

export interface updateEventVendor {
  updateEventVendor: updateEventVendor_updateEventVendor | null;
}

export interface updateEventVendorVariables {
  input: UpdateEventVendorRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeEventVendor
// ====================================================

export interface removeEventVendor {
  removeEventVendor: string | null;
}

export interface removeEventVendorVariables {
  eventId: number;
  vendorId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createGuestCategory
// ====================================================

export interface createGuestCategory_createGuestCategory {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface createGuestCategory {
  createGuestCategory: createGuestCategory_createGuestCategory;
}

export interface createGuestCategoryVariables {
  event_id: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateGuestCategory
// ====================================================

export interface updateGuestCategory_updateGuestCategory {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  message: string | null;
}

export interface updateGuestCategory {
  updateGuestCategory: updateGuestCategory_updateGuestCategory;
}

export interface updateGuestCategoryVariables {
  eventId: number;
  categoryId: number;
  name: string;
  message?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMessage
// ====================================================

export interface createMessage {
  createMessage: string;
}

export interface createMessageVariables {
  eventId: number;
  content: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: replyMessage
// ====================================================

export interface replyMessage {
  replyMessage: string | null;
}

export interface replyMessageVariables {
  eventId: number;
  messageId: number;
  reply: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addToInviteList
// ====================================================

export interface addToInviteList_addToInviteList_category {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  message: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface addToInviteList_addToInviteList {
  __typename: "InvitationList";
  id: number;
  uuid: string;
  event_id: number;
  event_uuid: string;
  first_name: string;
  other_name: string | null;
  last_name: string;
  title: string | null;
  phone: string;
  email: string | null;
  sent: boolean;
  category_id: number | null;
  category: addToInviteList_addToInviteList_category | null;
  slots: number | null;
  rsvp_status: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface addToInviteList {
  /**
   * Add a list of guests to the invitation list. It returns the any already guests.
   */
  addToInviteList: (addToInviteList_addToInviteList | null)[] | null;
}

export interface addToInviteListVariables {
  eventId: number;
  invite_list: AddToInviteListReq[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeFromInvitationList
// ====================================================

export interface removeFromInvitationList {
  removeFromInvitationList: string;
}

export interface removeFromInvitationListVariables {
  event_id: number;
  invitation_list_ids: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateInviteList
// ====================================================

export interface updateInviteList {
  updateInviteList: string | null;
}

export interface updateInviteListVariables {
  eventId: number;
  invite_list: UpdateInviteListReq[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addBackStory
// ====================================================

export interface addBackStory_addBackStory {
  __typename: "BackStory";
  title: string | null;
  id: number;
  event_id: number;
  role: BackStoryRole;
  story: string;
  image: string | null;
}

export interface addBackStory {
  addBackStory: addBackStory_addBackStory | null;
}

export interface addBackStoryVariables {
  eventId: number;
  input: addBackStoryReq;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBackStory
// ====================================================

export interface updateBackStory_updateBackStory {
  __typename: "BackStory";
  title: string | null;
  id: number;
  event_id: number;
  role: BackStoryRole;
  story: string;
}

export interface updateBackStory {
  updateBackStory: updateBackStory_updateBackStory | null;
}

export interface updateBackStoryVariables {
  eventId: number;
  input: updateBackStoryReq;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteBackStory
// ====================================================

export interface deleteBackStory {
  deleteBackStory: string | null;
}

export interface deleteBackStoryVariables {
  eventId: number;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addWishListItem
// ====================================================

export interface addWishListItem_addWishListItem {
  __typename: "WishListItem";
  id: number;
  name: string | null;
  image: string | null;
  amount: number | null;
}

export interface addWishListItem {
  addWishListItem: addWishListItem_addWishListItem | null;
}

export interface addWishListItemVariables {
  eventId: number;
  name: string;
  image: string;
  amount: number;
  currency?: Currency | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateWishListItem
// ====================================================

export interface updateWishListItem_updateWishListItem {
  __typename: "WishListItem";
  id: number;
  wish_list_id: number | null;
  name: string | null;
  image: string | null;
  amount: number | null;
}

export interface updateWishListItem {
  updateWishListItem: updateWishListItem_updateWishListItem | null;
}

export interface updateWishListItemVariables {
  eventId: number;
  itemId: number;
  name: string;
  image: string;
  amount: number;
  currency?: Currency | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteWishListItem
// ====================================================

export interface deleteWishListItem {
  deleteWishListItem: string;
}

export interface deleteWishListItemVariables {
  eventId: number;
  itemId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payForCashGiftV1
// ====================================================

export interface payForCashGiftV1 {
  /**
   * called when payment for cash gift is successful
   */
  payForCashGiftV1: boolean;
}

export interface payForCashGiftV1Variables {
  payload: payForCashGiftRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payForWishListItemV1
// ====================================================

export interface payForWishListItemV1 {
  /**
   * called when payment for a wish list item is successful
   */
  payForWishListItemV1: boolean;
}

export interface payForWishListItemV1Variables {
  payload: payForWishlistItemRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAlbum
// ====================================================

export interface createAlbum_createAlbum {
  __typename: "Album";
  id: string;
  name: string;
  description: string | null;
}

export interface createAlbum {
  createAlbum: createAlbum_createAlbum | null;
}

export interface createAlbumVariables {
  eventId: string;
  name: string;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addMedia
// ====================================================

export interface addMedia_addMedia_meta {
  __typename: "MediaMeta";
  s3_url: string | null;
  height: number | null;
  width: number | null;
}

export interface addMedia_addMedia {
  __typename: "AlbumMedia";
  id: string;
  name: string | null;
  url: string | null;
  tags: (AlbumMediaTag | null)[];
  type: string | null;
  meta: addMedia_addMedia_meta | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface addMedia {
  addMedia: addMedia_addMedia;
}

export interface addMediaVariables {
  eventId: string;
  url: string;
  tags: AlbumMediaTag[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddMediaToAlbum
// ====================================================

export interface AddMediaToAlbum {
  addMediaToAlbum: string;
}

export interface AddMediaToAlbumVariables {
  eventId: string;
  albumId: string;
  mediaIds: string[];
  tags?: AlbumMediaTag[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkDeleteMedia
// ====================================================

export interface BulkDeleteMedia {
  bulkDeleteMedia: string | null;
}

export interface BulkDeleteMediaVariables {
  eventId: string;
  mediaId: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMedia
// ====================================================

export interface deleteMedia {
  deleteMedia: string | null;
}

export interface deleteMediaVariables {
  eventId: string;
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAlbum
// ====================================================

export interface deleteAlbum {
  deleteAlbum: string | null;
}

export interface deleteAlbumVariables {
  eventId: string;
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteGuestCategory
// ====================================================

export interface deleteGuestCategory {
  deleteGuestCategory: string;
}

export interface deleteGuestCategoryVariables {
  event_id: number;
  categoryId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGiftHistory
// ====================================================

export interface getGiftHistory_getGiftHistory_meta {
  __typename: "Pagination";
  next: string | null;
  back: string | null;
}

export interface getGiftHistory_getGiftHistory_transaction_user {
  __typename: "User";
  id: number;
  /**
   * last name of the person
   */
  last_name: string | null;
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * phone number of the person
   */
  phone_number: string;
  email: any | null;
}

export interface getGiftHistory_getGiftHistory_transaction {
  __typename: "TransactionV1";
  /**
   * used for indexing
   */
  id: string;
  event_id: number;
  /**
   * for now [ USD | NGN ] ...all caps (upper case)
   */
  currency: Currency;
  /**
   * in kobo/cent depending on currency e.g N100 -> 10,000 & $12 -> 1,200
   */
  amount: number;
  /**
   * only provided if the transaction was for payment of a wishlist item
   */
  item_id: string | null;
  /**
   * returns if the transaction was made by an authorized user
   */
  user: getGiftHistory_getGiftHistory_transaction_user | null;
  /**
   * anonymous or the person's name
   */
  full_name: string | null;
  /**
   * if the transaction was made by an authenticated user
   */
  user_uuid: any | null;
  /**
   * [ successful | failed | pending ] ... (all lower case)
   */
  stage: string;
  /**
   * true|false (a summary of the stage of the transaction) ==>
   *
   * stage:successful-> true
   * &
   * stage:failed -> false
   */
  status: string;
}

export interface getGiftHistory_getGiftHistory {
  __typename: "TransactionWithPagination";
  meta: getGiftHistory_getGiftHistory_meta | null;
  transaction: (getGiftHistory_getGiftHistory_transaction | null)[] | null;
}

export interface getGiftHistory {
  getGiftHistory: getGiftHistory_getGiftHistory | null;
}

export interface getGiftHistoryVariables {
  eventId: string;
  cursor?: string | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGiftHistoryV2
// ====================================================

export interface getGiftHistoryV2_getGiftHistoryV2_user {
  __typename: "UserInfo";
  full_name: string;
  email: string;
  phone_number: string;
}

export interface getGiftHistoryV2_getGiftHistoryV2_gift {
  __typename: "GiftInfo";
  transactionId: string;
  name: string;
  gift_type: GiftType;
}

export interface getGiftHistoryV2_getGiftHistoryV2 {
  __typename: "GiftsHistory";
  /**
   * the transaction id from the wallet
   */
  transactionId: string;
  /**
   * user details of the user who made payment
   */
  user: getGiftHistoryV2_getGiftHistoryV2_user | null;
  /**
   * currency that the item was paid for in
   */
  currency: Currency;
  /**
   * amount paid for the item
   */
  amount: number;
  appreciated: boolean;
  walletId: string;
  /**
   * gift
   */
  gift: getGiftHistoryV2_getGiftHistoryV2_gift | null;
}

export interface getGiftHistoryV2 {
  getGiftHistoryV2: (getGiftHistoryV2_getGiftHistoryV2 | null)[];
}

export interface getGiftHistoryV2Variables {
  eventId: number;
  pageSize?: number | null;
  page: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: appreciateGift
// ====================================================

export interface appreciateGift {
  appreciateGift: boolean | null;
}

export interface appreciateGiftVariables {
  eventId: number;
  walletId: string;
  transactionId: string;
  appreciation: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEvent
// ====================================================

export interface createEvent_createEvent_event_owners_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
}

export interface createEvent_createEvent_event_owners {
  __typename: "EventOwner";
  id: number;
  user_id: number;
  user: createEvent_createEvent_event_owners_user | null;
  event_id: number;
  admin: boolean | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface createEvent_createEvent_event_type {
  __typename: "EventType";
  id: number;
  name: string;
  description: string;
}

export interface createEvent_createEvent {
  __typename: "Event";
  id: number;
  name: string;
  created_at: any | null;
  updated_at: any | null;
  /**
   * deprecated
   */
  event_owners: createEvent_createEvent_event_owners[];
  event_type: createEvent_createEvent_event_type | null;
}

export interface createEvent {
  createEvent: createEvent_createEvent | null;
}

export interface createEventVariables {
  name: string;
  eventTypeId: number;
  role?: string | null;
  hashTag?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEvent
// ====================================================

export interface updateEvent_updateEvent {
  __typename: "Event";
  id: number;
  name: string;
  welcome_msg: string | null;
  hash_tag: string | null;
}

export interface updateEvent {
  updateEvent: updateEvent_updateEvent | null;
}

export interface updateEventVariables {
  eventId: number;
  name?: string | null;
  hashTag?: string | null;
  welcomeMsg?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteEvent
// ====================================================

export interface deleteEvent {
  deleteEvent: string | null;
}

export interface deleteEventVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPassword
// ====================================================

export interface resetPassword {
  resetPassword: string;
}

export interface resetPasswordVariables {
  otp: string;
  password: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUserProfile
// ====================================================

export interface updateUserProfile_updateUserProfile {
  __typename: "User";
  id: number;
  title: string | null;
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  other_name: string | null;
}

export interface updateUserProfile {
  updateUserProfile: updateUserProfile_updateUserProfile | null;
}

export interface updateUserProfileVariables {
  input: UpdateUserProfileRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateInvitationCard
// ====================================================

export interface updateInvitationCard_updateInvitationCard {
  __typename: "InvitationCard";
  id: number | null;
  event_id: number | null;
  template: string | null;
  updated_at: any | null;
  created_at: any | null;
}

export interface updateInvitationCard {
  updateInvitationCard: updateInvitationCard_updateInvitationCard | null;
}

export interface updateInvitationCardVariables {
  eventId: number;
  template: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changePassword
// ====================================================

export interface changePassword {
  changePassword: string | null;
}

export interface changePasswordVariables {
  newPassword: string;
  oldPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEventSlug
// ====================================================

export interface updateEventSlug_updateEventSlug {
  __typename: "Event";
  id: number;
  name: string;
  hash_tag: string | null;
  slug: string | null;
  welcome_msg: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface updateEventSlug {
  updateEventSlug: updateEventSlug_updateEventSlug | null;
}

export interface updateEventSlugVariables {
  eventId: number;
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: editEvent
// ====================================================

export interface editEvent_updateEventSlug {
  __typename: "Event";
  id: number;
  name: string;
  slug: string | null;
  welcome_msg: string | null;
}

export interface editEvent_updateEvent {
  __typename: "Event";
  id: number;
  name: string;
  hash_tag: string | null;
  welcome_msg: string | null;
}

export interface editEvent {
  updateEventSlug: editEvent_updateEventSlug | null;
  updateEvent: editEvent_updateEvent | null;
}

export interface editEventVariables {
  eventId: number;
  slug: string;
  updateEventEventId2: number;
  name?: string | null;
  hashTag?: string | null;
  welcomeMsg?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMessageById
// ====================================================

export interface deleteMessageById {
  deleteMessageById: string;
}

export interface deleteMessageByIdVariables {
  eventId: number;
  messageId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: transferToBank
// ====================================================

export interface transferToBank_transferToBank {
  __typename: "TransferResponse";
  status: boolean;
  otp_is_required: boolean;
}

export interface transferToBank {
  transferToBank: transferToBank_transferToBank | null;
}

export interface transferToBankVariables {
  input?: transferFromWalletRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMultipleGuestCategories
// ====================================================

export interface createMultipleGuestCategories_createMultipleGuestCategories {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface createMultipleGuestCategories {
  createMultipleGuestCategories:
    | createMultipleGuestCategories_createMultipleGuestCategories[]
    | null;
}

export interface createMultipleGuestCategoriesVariables {
  eventId: number;
  names?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendInvitations
// ====================================================

export interface sendInvitations_sendInvitations_status {
  __typename: "SendInvitationResponseStatus";
  id: string;
  error: string | null;
}

export interface sendInvitations_sendInvitations {
  __typename: "SendInvitationsResponse";
  channel: string;
  status: sendInvitations_sendInvitations_status[];
}

export interface sendInvitations {
  sendInvitations: sendInvitations_sendInvitations | null;
}

export interface sendInvitationsVariables {
  eventId: string;
  req: SendInviteReq;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRsvpStatus
// ====================================================

export interface updateRsvpStatus {
  updateRsvpStatus: boolean;
}

export interface updateRsvpStatusVariables {
  eventId: number;
  status: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyTransaction
// ====================================================

export interface verifyTransaction_verifyTransaction {
  __typename: "TransactionV1";
  /**
   * used for indexing
   */
  id: string;
  /**
   * reference -> very important (takes precedence over the id)
   */
  reference: string;
  /**
   * e.g paystack, monnify, stripe, flutterwave ... (all lower case)
   */
  payment_processor: string;
  /**
   * the id of the event for which the transaction was made
   */
  event_uuid: any;
  event_id: number;
  /**
   * if the transaction was made by an authenticated user
   */
  user_uuid: any | null;
  /**
   * anonymous or the person's name
   */
  full_name: string | null;
  /**
   * for now [ USD | NGN ] ...all caps (upper case)
   */
  currency: Currency;
  /**
   * in kobo/cent depending on currency e.g N100 -> 10,000 & $12 -> 1,200
   */
  amount: number;
  /**
   * true|false (a summary of the stage of the transaction) ==>
   *
   * stage:successful-> true
   * &
   * stage:failed -> false
   */
  status: string;
  /**
   * [ successful | failed | pending ] ... (all lower case)
   */
  stage: string;
  /**
   * gift, gift-fee, withdraw, withdraw-fee
   */
  kind: string;
}

export interface verifyTransaction {
  verifyTransaction: verifyTransaction_verifyTransaction;
}

export interface verifyTransactionVariables {
  reference: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetMediaPresignedUrl
// ====================================================

export interface GetMediaPresignedUrl_getMediaPresignedUrl {
  __typename: "MediaPresignURLResponse";
  upload_url: string;
  file_uri: string;
  expiry_time: any;
  expiry_time_in_seconds: number;
  event_id: string;
  media_id: string;
}

export interface GetMediaPresignedUrl {
  getMediaPresignedUrl: GetMediaPresignedUrl_getMediaPresignedUrl;
}

export interface GetMediaPresignedUrlVariables {
  eventId: string;
  mediaType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyMediaUpload
// ====================================================

export interface VerifyMediaUpload {
  verifyMediaUpload: string;
}

export interface VerifyMediaUploadVariables {
  eventId: string;
  fileUri: string;
  tags: AlbumMediaTag[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: generalInviteListReq
// ====================================================

export interface generalInviteListReq_GeneralInviteListReq_auth_response_user {
  __typename: "User";
  id: number;
}

export interface generalInviteListReq_GeneralInviteListReq_auth_response {
  __typename: "AuthResponse";
  access_expiry: any | null;
  access_token: string;
  refresh_token: string;
  user: generalInviteListReq_GeneralInviteListReq_auth_response_user | null;
}

export interface generalInviteListReq_GeneralInviteListReq {
  __typename: "GeneralInviteResp";
  auth_response: generalInviteListReq_GeneralInviteListReq_auth_response;
}

export interface generalInviteListReq {
  GeneralInviteListReq: generalInviteListReq_GeneralInviteListReq;
}

export interface generalInviteListReqVariables {
  args: AddToInviteListReq;
  eventToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addEventTeamMember
// ====================================================

export interface addEventTeamMember {
  addEventTeamMember: boolean | null;
}

export interface addEventTeamMemberVariables {
  team: TeamMember;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeTeamMember
// ====================================================

export interface removeTeamMember {
  removeTeamMember: boolean | null;
}

export interface removeTeamMemberVariables {
  eventId: number;
  ownerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptEventTeam
// ====================================================

export interface acceptEventTeam_acceptEventTeam {
  __typename: "AcceptEventTeam";
  event_id: number;
  event_name: string;
  first_name: string;
  last_name: string;
  role: eventTeamRole;
}

export interface acceptEventTeam {
  acceptEventTeam: acceptEventTeam_acceptEventTeam | null;
}

export interface acceptEventTeamVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addCustomMyPlanTask
// ====================================================

export interface addCustomMyPlanTask_addCustomMyPlanTask {
  __typename: "EventTask";
  id: any;
  event_id: number;
  eventUUID: any;
  name: string;
  description: string;
  done: boolean | null;
}

export interface addCustomMyPlanTask {
  addCustomMyPlanTask: addCustomMyPlanTask_addCustomMyPlanTask;
}

export interface addCustomMyPlanTaskVariables {
  eventId: number;
  taskName: string;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteCustomMyPlanTask
// ====================================================

export interface deleteCustomMyPlanTask {
  deleteCustomMyPlanTask: boolean | null;
}

export interface deleteCustomMyPlanTaskVariables {
  eventId: number;
  taskId: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: editCustomMyPlanTask
// ====================================================

export interface editCustomMyPlanTask_editCustomMyPlanTask {
  __typename: "EventTask";
  id: any;
  event_id: number;
  eventUUID: any;
  name: string;
  description: string;
  done: boolean | null;
}

export interface editCustomMyPlanTask {
  editCustomMyPlanTask: editCustomMyPlanTask_editCustomMyPlanTask;
}

export interface editCustomMyPlanTaskVariables {
  eventId: number;
  taskId: any;
  taskName: string;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDoneStatus
// ====================================================

export interface updateDoneStatus {
  updateDoneStatus: boolean | null;
}

export interface updateDoneStatusVariables {
  eventId: number;
  taskId: any;
  isDone: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setHeroImage
// ====================================================

export interface setHeroImage {
  setHeroImage: string | null;
}

export interface setHeroImageVariables {
  setHeroImageEventId2: number;
  image: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeHeroImage
// ====================================================

export interface removeHeroImage {
  removeHeroImage: string | null;
}

export interface removeHeroImageVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addStreamerFromInviteList
// ====================================================

export interface addStreamerFromInviteList {
  /**
   * STREAMERS
   */
  addStreamerFromInviteList: boolean | null;
}

export interface addStreamerFromInviteListVariables {
  args: CreateStreamer[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeStreamerFromInviteList
// ====================================================

export interface removeStreamerFromInviteList {
  removeStreamerFromInviteList: boolean | null;
}

export interface removeStreamerFromInviteListVariables {
  streamerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateStreamer
// ====================================================

export interface updateStreamer {
  updateStreamer: boolean | null;
}

export interface updateStreamerVariables {
  args: UpdateStreamer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEventPreference
// ====================================================

export interface updateEventPreference_updateEventPreference {
  __typename: "EventPreference";
  id: number;
  event_id: number;
  food: boolean | null;
  ads: boolean | null;
  asoebi_selection: boolean | null;
  gifts: boolean | null;
  store: boolean | null;
  vendor_store: boolean | null;
  live_hall: boolean | null;
  reservation: boolean | null;
  dress_code: boolean | null;
  map: boolean | null;
  smart_send: boolean | null;
  private: boolean | null;
}

export interface updateEventPreference {
  updateEventPreference: updateEventPreference_updateEventPreference | null;
}

export interface updateEventPreferenceVariables {
  input?: UpdateEventPreferenceRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createLiveSchedule
// ====================================================

export interface createLiveSchedule {
  createLiveSchedule: string | null;
}

export interface createLiveScheduleVariables {
  eventUuid: string;
  eventPartUuid: string;
  link: string;
  date: string;
  time: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateLiveSchedule
// ====================================================

export interface updateLiveSchedule {
  updateLiveSchedule: string | null;
}

export interface updateLiveScheduleVariables {
  updateLiveScheduleId: string;
  link?: string | null;
  date?: string | null;
  time?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteLiveSchedule
// ====================================================

export interface deleteLiveSchedule {
  deleteLiveSchedule: boolean;
}

export interface deleteLiveScheduleVariables {
  deleteLiveScheduleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationListBySearch
// ====================================================

export interface invitationListBySearch_invitationListBySearch_category {
  __typename: "Category";
  id: number;
  name: string;
}

export interface invitationListBySearch_invitationListBySearch {
  __typename: "InvitationList";
  event_id: number;
  email: string | null;
  first_name: string;
  id: number;
  last_name: string;
  phone: string;
  category: invitationListBySearch_invitationListBySearch_category | null;
  rsvp_status: string | null;
  title: string | null;
}

export interface invitationListBySearch {
  invitationListBySearch: invitationListBySearch_invitationListBySearch[];
}

export interface invitationListBySearchVariables {
  eventId: number;
  searchTerm?: string | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: estimateInvitationCost
// ====================================================

export interface estimateInvitationCost_estimateInvitationCost {
  __typename: "EstimateInvitationCostResp";
  currency: Currency;
  usd_rate: number;
  rate: number;
  cost: number;
}

export interface estimateInvitationCost {
  estimateInvitationCost: estimateInvitationCost_estimateInvitationCost;
}

export interface estimateInvitationCostVariables {
  eventUuid: string;
  req: EstimateInvitationCostReq;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addGuestToInviteList
// ====================================================

export interface addGuestToInviteList {
  /**
   * Add a single guest to the invitation list.
   */
  addGuestToInviteList: string | null;
}

export interface addGuestToInviteListVariables {
  eventId: number;
  guest: AddToInviteListReq;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setWalletPin
// ====================================================

export interface setWalletPin {
  setWalletPin: string;
}

export interface setWalletPinVariables {
  eventId: number;
  newPin: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifySetPinRequest
// ====================================================

export interface verifySetPinRequest {
  verifySetPinRequest: string;
}

export interface verifySetPinRequestVariables {
  otp: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: transferThatExceedsThreshold
// ====================================================

export interface transferThatExceedsThreshold_transferThatExceedsThreshold {
  __typename: "TransferResponse";
  otp_is_required: boolean;
  status: boolean;
}

export interface transferThatExceedsThreshold {
  transferThatExceedsThreshold: transferThatExceedsThreshold_transferThatExceedsThreshold | null;
}

export interface transferThatExceedsThresholdVariables {
  otp: string;
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyPinResetOTP
// ====================================================

export interface verifyPinResetOTP {
  verifyPinResetOTP: string;
}

export interface verifyPinResetOTPVariables {
  eventId: number;
  otp: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: initiateWalletPinReset
// ====================================================

export interface initiateWalletPinReset {
  initiateWalletPinReset: string;
}

export interface initiateWalletPinResetVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetWalletPin
// ====================================================

export interface resetWalletPin {
  resetWalletPin: string;
}

export interface resetWalletPinVariables {
  eventId: number;
  passcode: string;
  newPin: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setForeignAccountDetails
// ====================================================

export interface setForeignAccountDetails {
  setForeignAccountDetails: boolean;
}

export interface setForeignAccountDetailsVariables {
  args: foreignAccountDetails;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateForeignAccountDetails
// ====================================================

export interface updateForeignAccountDetails {
  updateForeignAccountDetails: boolean;
}

export interface updateForeignAccountDetailsVariables {
  args: foreignAccountDetails;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteForeignAccountDetails
// ====================================================

export interface deleteForeignAccountDetails {
  deleteForeignAccountDetails: boolean;
}

export interface deleteForeignAccountDetailsVariables {
  foreignAccountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: eventVendor
// ====================================================

export interface eventVendor_eventVendor {
  __typename: "EventVendor";
  id: number;
  event_id: number;
  instagram_handle: string | null;
  role: string | null;
  name: string | null;
  email: string | null;
  phone_number: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface eventVendor {
  eventVendor: (eventVendor_eventVendor | null)[];
}

export interface eventVendorVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMessageByUser
// ====================================================

export interface getMessageByUser_getMessageByUser_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  /**
   * phone number of the person
   */
  phone_number: string;
}

export interface getMessageByUser_getMessageByUser {
  __typename: "Message";
  id: number;
  content: string | null;
  status: boolean;
  event_id: number;
  updated_at: any | null;
  created_at: any | null;
  user: getMessageByUser_getMessageByUser_user | null;
}

export interface getMessageByUser {
  getMessageByUser: (getMessageByUser_getMessageByUser | null)[] | null;
}

export interface getMessageByUserVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMessagesByEvent
// ====================================================

export interface getMessagesByEvent_getMessagesByEvent_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  /**
   * phone number of the person
   */
  phone_number: string;
}

export interface getMessagesByEvent_getMessagesByEvent {
  __typename: "Message";
  id: number;
  event_id: number;
  user: getMessagesByEvent_getMessagesByEvent_user | null;
  content: string | null;
  status: boolean;
  created_at: any | null;
  updated_at: any | null;
}

export interface getMessagesByEvent {
  getMessagesByEvent: (getMessagesByEvent_getMessagesByEvent | null)[] | null;
}

export interface getMessagesByEventVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: guestCategory
// ====================================================

export interface guestCategory_guestCategory {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface guestCategory {
  guestCategory: guestCategory_guestCategory[];
}

export interface guestCategoryVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: noGuestPerCategory
// ====================================================

export interface noGuestPerCategory_noGuestPerCategory {
  __typename: "GuestPerCategory";
  category_id: number;
  category_name: string;
  message: string | null;
  num_guest: number | null;
}

export interface noGuestPerCategory {
  noGuestPerCategory: (noGuestPerCategory_noGuestPerCategory | null)[];
}

export interface noGuestPerCategoryVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationList
// ====================================================

export interface invitationList_invitationList_category {
  __typename: "Category";
  id: number;
  name: string;
  event_id: number;
}

export interface invitationList_invitationList {
  __typename: "InvitationList";
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string | null;
  category: invitationList_invitationList_category | null;
}

export interface invitationList {
  invitationList: invitationList_invitationList[];
}

export interface invitationListVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBackStory
// ====================================================

export interface getBackStory_getBackStory {
  __typename: "BackStory";
  id: number;
  role: BackStoryRole;
  story: string;
  title: string | null;
  image: string | null;
  event_id: number;
}

export interface getBackStory {
  getBackStory: (getBackStory_getBackStory | null)[] | null;
}

export interface getBackStoryVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWishListItems
// ====================================================

export interface getWishListItems_getWishListItems {
  __typename: "WishListItem";
  id: number;
  wish_list_id: number | null;
  name: string | null;
  image: string | null;
  amount: number | null;
  currency: string | null;
  paid: boolean | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface getWishListItems {
  getWishListItems: (getWishListItems_getWishListItems | null)[] | null;
}

export interface getWishListItemsVariables {
  eventId: number;
  listId?: number | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationListByRSVPStatus
// ====================================================

export interface invitationListByRSVPStatus_invitationListByRSVPStatus_category {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  created_at: any | null;
  updated_at: any | null;
}

export interface invitationListByRSVPStatus_invitationListByRSVPStatus {
  __typename: "InvitationList";
  id: number;
  event_id: number;
  first_name: string;
  last_name: string;
  phone: string;
  sent: boolean;
  email: string | null;
  rsvp_status: string | null;
  category: invitationListByRSVPStatus_invitationListByRSVPStatus_category | null;
}

export interface invitationListByRSVPStatus {
  invitationListByRSVPStatus: invitationListByRSVPStatus_invitationListByRSVPStatus[];
}

export interface invitationListByRSVPStatusVariables {
  eventId: number;
  rsvpStatus: RSVPStatus;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: eventParts
// ====================================================

export interface eventParts_eventParts {
  __typename: "EventPart";
  id: number;
  uuid: string;
  event_id: number;
  name: string;
  part: string;
  time: any;
  welcome_msg: string | null;
  address: string;
  city: string | null;
  state: string | null;
  country: string | null;
  map_coordinate: string | null;
  created_at: any | null;
  updated_at: any | null;
  geo_position: {
    geo_position_id: string;
    latitude: string;
    longitude: string;
  };
}

export interface eventParts {
  eventParts: eventParts_eventParts[];
}

export interface eventPartsVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Query
// ====================================================

export interface Query {
  /**
   * return all the tags a user can use
   */
  getMediaTags: AlbumMediaTag[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMedia
// ====================================================

export interface GetMedia_getMedia_meta {
  __typename: "MediaMeta";
  s3_url: string | null;
  height: number | null;
  width: number | null;
}

export interface GetMedia_getMedia_album_creator {
  __typename: "Creator";
  user_id: any | null;
  first_name: string | null;
  last_name: string | null;
  image: string | null;
}

export interface GetMedia_getMedia_album {
  __typename: "Album";
  id: string;
  name: string;
  event_id: any;
  description: string | null;
  created_by: any | null;
  creator: GetMedia_getMedia_album_creator | null;
  created_at: any;
  updated_at: any | null;
}

export interface GetMedia_getMedia {
  __typename: "AlbumMedia";
  id: string;
  name: string | null;
  url: string | null;
  tags: (AlbumMediaTag | null)[];
  type: string | null;
  meta: GetMedia_getMedia_meta | null;
  private: boolean | null;
  user_id: any | null;
  event_id: any | null;
  album_id: any | null;
  album: GetMedia_getMedia_album | null;
  likes: number | null;
  user_type: UserTypeEnum | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface GetMedia {
  getMedia: (GetMedia_getMedia | null)[] | null;
}

export interface GetMediaVariables {
  eventId: string;
  filter: MediaFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAlbums
// ====================================================

export interface GetAlbums_getAlbums_data_Media_data_album {
  __typename: "Album";
  created_at: any;
  created_by: any | null;
  name: string;
}

export interface GetAlbums_getAlbums_data_Media_data {
  __typename: "AlbumMedia";
  album: GetAlbums_getAlbums_data_Media_data_album | null;
  created_at: any | null;
  id: string;
  event_id: any | null;
  album_id: any | null;
  name: string | null;
  tags: (AlbumMediaTag | null)[];
  type: string | null;
  url: string | null;
}

export interface GetAlbums_getAlbums_data_Media {
  __typename: "PaginatedAlbumMedia";
  data: GetAlbums_getAlbums_data_Media_data[];
}

export interface GetAlbums_getAlbums_data {
  __typename: "Album";
  id: string;
  name: string;
  event_id: any;
  description: string | null;
  created_by: any | null;
  Media: GetAlbums_getAlbums_data_Media;
  created_at: any;
  updated_at: any | null;
}

export interface GetAlbums_getAlbums {
  __typename: "PaginatedAlbum";
  data: GetAlbums_getAlbums_data[];
}

export interface GetAlbums {
  getAlbums: GetAlbums_getAlbums;
}

export interface GetAlbumsVariables {
  eventId: string;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventType
// ====================================================

export interface getEventType_getEventType {
  __typename: "EventType";
  id: number;
  name: string;
  description: string;
}

export interface getEventType {
  getEventType: getEventType_getEventType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventsForDashboard
// ====================================================

export interface getEventsForDashboard_events_event_wallet {
  __typename: "Wallet";
  /**
   * the uuid that identifies the wallet
   */
  id: string;
  balance: number;
  currency: Currency;
  pin_is_set: boolean;
  /**
   * is true if the wallet has been frozen
   */
  frozen: boolean;
  /**
   * time when the wallet was created
   */
  created_at: any | null;
  /**
   * last updated at
   */
  updated_at: any | null;
}

export interface getEventsForDashboard_events_event_owners_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  id: number;
}

export interface getEventsForDashboard_events_event_owners {
  __typename: "EventOwner";
  id: number;
  user_id: number;
  event_id: number;
  admin: boolean | null;
  role: string | null;
  user: getEventsForDashboard_events_event_owners_user | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface getEventsForDashboard_events_event_type {
  __typename: "EventType";
  id: number;
  name: string;
  description: string;
}

export interface getEventsForDashboard_events_event_preference {
  __typename: "EventPreference";
  id: number;
  uuid: string;
  event_id: number;
  food: boolean | null;
  ads: boolean | null;
  asoebi_selection: boolean | null;
  gifts: boolean | null;
  store: boolean | null;
  vendor_store: boolean | null;
  reservation: boolean | null;
  dress_code: boolean | null;
  map: boolean | null;
  smart_send: boolean | null;
  private: boolean | null;
  live_hall: boolean | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface getEventsForDashboard_events {
  __typename: "Event";
  id: number;
  uuid: string;
  name: string;
  wallet_created: boolean | null;
  hash_tag: string | null;
  /**
   * deprecated
   */
  token: string | null;
  slug: string | null;
  toast: string | null;
  welcome_msg: string | null;
  created_at: any | null;
  updated_at: any | null;
  date: string | null;
  event_wallet: (getEventsForDashboard_events_event_wallet | null)[] | null;
  /**
   * deprecated
   */
  event_owners: getEventsForDashboard_events_event_owners[];
  event_type: getEventsForDashboard_events_event_type | null;
  event_preference: getEventsForDashboard_events_event_preference | null;
  is_admin: boolean;
}

export interface getEventsForDashboard_user {
  __typename: "User";
  id: number;
  /**
   * phone number of the person
   */
  phone_number: string;
}

export interface getEventsForDashboard {
  events: (getEventsForDashboard_events | null)[];
  user: getEventsForDashboard_user | null;
}

export interface getEventsForDashboardVariables {
  isInvited?: boolean | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventByIdDashboardLayout
// ====================================================

export interface getEventByIdDashboardLayout_events_event_owners_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  uuid: any | null;
  id: number;
}

export interface getEventByIdDashboardLayout_events_event_owners {
  __typename: "EventOwner";
  id: number;
  user: getEventByIdDashboardLayout_events_event_owners_user | null;
  admin: boolean | null;
  role: string | null;
}

export interface getEventByIdDashboardLayout_events {
  __typename: "Event";
  id: number;
  name: string;
  /**
   * deprecated
   */
  event_owners: getEventByIdDashboardLayout_events_event_owners[];
}

export interface getEventByIdDashboardLayout {
  events: (getEventByIdDashboardLayout_events | null)[];
}

export interface getEventByIdDashboardLayoutVariables {
  isInvited?: boolean | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: events
// ====================================================

export interface events_events_event_wallet {
  __typename: "Wallet";
  /**
   * the uuid that identifies the wallet
   */
  id: string;
  balance: number;
  currency: Currency;
  pin_is_set: boolean;
  /**
   * is true if the wallet has been frozen
   */
  frozen: boolean;
  /**
   * time when the wallet was created
   */
  created_at: any | null;
  /**
   * last updated at
   */
  updated_at: any | null;
}

export interface events_events_event_owners_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  image: string | null;
}

export interface events_events_event_owners {
  __typename: "EventOwner";
  id: number;
  user_id: number;
  event_id: number;
  admin: boolean | null;
  role: string | null;
  user: events_events_event_owners_user | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface events_events_event_type {
  __typename: "EventType";
  id: number;
  name: string;
  description: string;
}

export interface events_events_event_parts {
  __typename: "EventPart";
  id: number;
  uuid: string;
  event_id: number;
  name: string;
  part: string;
  time: any;
  welcome_msg: string | null;
  address: string;
  city: string | null;
  state: string | null;
  country: string | null;
  map_coordinate: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface events_events_event_preference {
  __typename: "EventPreference";
  id: number;
  uuid: string;
  event_id: number;
  food: boolean | null;
  ads: boolean | null;
  asoebi_selection: boolean | null;
  gifts: boolean | null;
  store: boolean | null;
  vendor_store: boolean | null;
  reservation: boolean | null;
  dress_code: boolean | null;
  map: boolean | null;
  smart_send: boolean | null;
  private: boolean | null;
  live_hall: boolean | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface events_events {
  __typename: "Event";
  id: number;
  uuid: string;
  name: string;
  wallet_created: boolean | null;
  hash_tag: string | null;
  /**
   * deprecated
   */
  token: string | null;
  slug: string | null;
  toast: string | null;
  welcome_msg: string | null;
  created_at: any | null;
  updated_at: any | null;
  date: string | null;
  event_wallet: (events_events_event_wallet | null)[] | null;
  /**
   * deprecated
   */
  event_owners: events_events_event_owners[];
  event_type: events_events_event_type | null;
  event_parts: events_events_event_parts[] | null;
  event_preference: events_events_event_preference | null;
  is_admin: boolean;
}

export interface events {
  events: (events_events | null)[];
}

export interface eventsVariables {
  isInvited?: boolean | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationCard
// ====================================================

export interface invitationCard_invitationCard {
  __typename: "InvitationCard";
  id: number | null;
  event_id: number | null;
  template: string | null;
  updated_at: any | null;
  created_at: any | null;
}

export interface invitationCard {
  invitationCard: invitationCard_invitationCard | null;
}

export interface invitationCardVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: event
// ====================================================

export interface event_event {
  __typename: "Event";
  id: number;
  /**
   * deprecated
   */
  token: string | null;
  slug: string | null;
}

export interface event {
  event: event_event | null;
}

export interface eventVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventPreference
// ====================================================

export interface getEventPreference_event_event_preference {
  __typename: "EventPreference";
  ads: boolean | null;
  asoebi_selection: boolean | null;
  created_at: any | null;
  dress_code: boolean | null;
  event_id: number;
  id: number;
  food: boolean | null;
  gifts: boolean | null;
  map: boolean | null;
  private: boolean | null;
  reservation: boolean | null;
  smart_send: boolean | null;
  store: boolean | null;
  updated_at: any | null;
  vendor_store: boolean | null;
}

export interface getEventPreference_event {
  __typename: "Event";
  event_preference: getEventPreference_event_event_preference | null;
}

export interface getEventPreference {
  event: getEventPreference_event | null;
}

export interface getEventPreferenceVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: user
// ====================================================

export interface user_user {
  __typename: "User";
  id: number;
  /**
   * gender of the person
   */
  gender: Gender | null;
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  email: any | null;
  /**
   * phone number of the person
   */
  phone_number: string;
  image: string | null;
}

export interface user {
  user: user_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUsername
// ====================================================

export interface getUsername_user {
  __typename: "User";
  id: number;
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  email: any | null;
  image: string | null;
  /**
   * phone number of the person
   */
  phone_number: string;
}

export interface getUsername {
  user: getUsername_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEventInfo
// ====================================================

export interface GetEventInfo_event {
  __typename: "Event";
  id: number;
  name: string;
  hash_tag: string | null;
  slug: string | null;
  welcome_msg: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface GetEventInfo {
  event: GetEventInfo_event | null;
}

export interface GetEventInfoVariables {
  eventEventId2: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBanksWithRave
// ====================================================

export interface getBanksWithRave_getBanksWithRave {
  __typename: "Bank";
  id: number | null;
  code: string | null;
  name: string | null;
}

export interface getBanksWithRave {
  getBanksWithRave: (getBanksWithRave_getBanksWithRave | null)[];
}

export interface getBanksWithRaveVariables {
  country: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: resolveAccountWithRave
// ====================================================

export interface resolveAccountWithRave_resolveAccountWithRave {
  __typename: "Account";
  accountName: string | null;
  accountNumber: string | null;
}

export interface resolveAccountWithRave {
  resolveAccountWithRave: resolveAccountWithRave_resolveAccountWithRave | null;
}

export interface resolveAccountWithRaveVariables {
  accountNumber: string;
  accountBank: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: filterInvitationListBy
// ====================================================

export interface filterInvitationListBy_filterInvitationListBy_status_whatsapp {
  __typename: "InvitationListDeliveryTime";
  sent_at: any | null;
  delivered_at: any | null;
  read_at: any | null;
  failed_at: any | null;
  failure_reason: string | null;
}

export interface filterInvitationListBy_filterInvitationListBy_status_sms {
  __typename: "InvitationListDeliveryTime";
  sent_at: any | null;
  delivered_at: any | null;
  read_at: any | null;
  failed_at: any | null;
  failure_reason: string | null;
}

export interface filterInvitationListBy_filterInvitationListBy_status_email {
  __typename: "InvitationListDeliveryTime";
  sent_at: any | null;
  delivered_at: any | null;
  read_at: any | null;
  failed_at: any | null;
  failure_reason: string | null;
}

export interface filterInvitationListBy_filterInvitationListBy_status {
  __typename: "InvitationListDeliveryStatus";
  whatsapp: filterInvitationListBy_filterInvitationListBy_status_whatsapp | null;
  sms: filterInvitationListBy_filterInvitationListBy_status_sms | null;
  email: filterInvitationListBy_filterInvitationListBy_status_email | null;
}

export interface filterInvitationListBy_filterInvitationListBy_category {
  __typename: "Category";
  id: number;
  event_id: number;
  name: string;
  message: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface filterInvitationListBy_filterInvitationListBy {
  __typename: "InvitationList";
  id: number;
  event_id: number;
  event_uuid: string;
  uuid: string;
  first_name: string;
  other_name: string | null;
  last_name: string;
  title: string | null;
  phone: string;
  email: string | null;
  sent: boolean;
  category_id: number | null;
  status: filterInvitationListBy_filterInvitationListBy_status | null;
  category: filterInvitationListBy_filterInvitationListBy_category | null;
  rsvp_status: string | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface filterInvitationListBy {
  filterInvitationListBy:
    | (filterInvitationListBy_filterInvitationListBy | null)[]
    | null;
}

export interface filterInvitationListByVariables {
  eventId: number;
  filter: invitationListFilter;
  page: number;
  pageSize: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPov
// ====================================================

export interface GetPov_getPov_guest {
  __typename: "User";
  id: number;
  uuid: any | null;
  email: any | null;
  /**
   * first name of the person
   */
  first_name: string;
  image: string | null;
  /**
   * last name of the person
   */
  last_name: string | null;
  other_name: string | null;
  title: string | null;
  /**
   * gender of the person
   */
  gender: Gender | null;
  /**
   * phone number of the person
   */
  phone_number: string;
  verified: boolean;
  guest: boolean | null;
  created_at: any | null;
  updated_at: any | null;
  lastLoggedInAt: any | null;
}

export interface GetPov_getPov_media_meta {
  __typename: "MediaMeta";
  s3_url: string | null;
  height: number | null;
  width: number | null;
}

export interface GetPov_getPov_media_album {
  __typename: "Album";
  id: string;
  name: string;
}

export interface GetPov_getPov_media {
  __typename: "AlbumMedia";
  id: string;
  name: string | null;
  url: string | null;
  tags: (AlbumMediaTag | null)[];
  type: string | null;
  meta: GetPov_getPov_media_meta | null;
  private: boolean | null;
  user_id: any | null;
  event_id: any | null;
  album_id: any | null;
  album: GetPov_getPov_media_album | null;
  likes: number | null;
  user_type: UserTypeEnum | null;
  created_at: any | null;
  updated_at: any | null;
}

export interface GetPov_getPov {
  __typename: "Pov";
  guest: GetPov_getPov_guest;
  total_likes: number;
  total_media_count: number;
  total_videos: number;
  total_pictures: number;
  media: GetPov_getPov_media[];
}

export interface GetPov {
  getPov: GetPov_getPov[];
}

export interface GetPovVariables {
  eventId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: refresh
// ====================================================

export interface refresh_refresh {
  __typename: "AuthResponse";
  refresh_token: string;
  access_token: string;
  access_expiry: any | null;
}

export interface refresh {
  /**
   * deprecated
   */
  refresh: refresh_refresh;
}

export interface refreshVariables {
  refresh_token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: logout
// ====================================================

export interface logout {
  logout: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventDetailsWithToken
// ====================================================

export interface getEventDetailsWithToken_getEventDetailsWithToken_event {
  __typename: "Event";
  name: string;
  id: number;
  welcome_msg: string | null;
  slug: string | null;
}

export interface getEventDetailsWithToken_getEventDetailsWithToken_invitation_card {
  __typename: "InvitationCard";
  template: string | null;
}

export interface getEventDetailsWithToken_getEventDetailsWithToken {
  __typename: "GeneralInvitationResp";
  event: getEventDetailsWithToken_getEventDetailsWithToken_event | null;
  invitation_card: getEventDetailsWithToken_getEventDetailsWithToken_invitation_card | null;
}

export interface getEventDetailsWithToken {
  getEventDetailsWithToken: getEventDetailsWithToken_getEventDetailsWithToken | null;
}

export interface getEventDetailsWithTokenVariables {
  eventToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTeamMember
// ====================================================

export interface getTeamMember_getTeamMember_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  id: number;
  /**
   * phone number of the person
   */
  phone_number: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  verified: boolean;
  email: any | null;
  image: string | null;
}

export interface getTeamMember_getTeamMember {
  __typename: "EventOwner";
  event_id: number;
  user_id: number;
  user: getTeamMember_getTeamMember_user | null;
  admin: boolean | null;
  id: number;
  role: string | null;
}

export interface getTeamMember {
  getTeamMember: getTeamMember_getTeamMember[];
}

export interface getTeamMemberVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkEventSlug
// ====================================================

export interface checkEventSlug {
  checkEventSlug: boolean;
}

export interface checkEventSlugVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventStreamers
// ====================================================

export interface getEventStreamers_getEventStreamers {
  __typename: "Streamer";
  Id: string;
  event_uuid: string;
  is_streaming: boolean;
  playback_url: string;
  ingest_endpoint: string;
  stream_key: string;
  deviceName: string;
  viewingUrl: string;
}

export interface getEventStreamers {
  /**
   * STREAMERS
   */
  getEventStreamers: (getEventStreamers_getEventStreamers | null)[] | null;
}

export interface getEventStreamersVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventPlan
// ====================================================

export interface getEventPlan_getEventPlan {
  __typename: "EventPlan";
  website_template: boolean;
  wallet_pin_set: boolean;
  send_out_invites: boolean;
  upload_pictures: boolean;
  invitation_card: boolean;
  event_id: number;
  event_details: boolean;
  build_invite_list: boolean;
  categories: boolean;
  add_wishlist: boolean;
  add_event_vendors: boolean;
  add_back_story: boolean;
  add_foreign_account: boolean;
  add_live_hall: boolean;
}

export interface getEventPlan {
  getEventPlan: getEventPlan_getEventPlan;
}

export interface getEventPlanVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCustomMyPlanTask
// ====================================================

export interface getCustomMyPlanTask_getCustomMyPlanTask {
  __typename: "EventTask";
  id: any;
  event_id: number;
  eventUUID: any;
  name: string;
  description: string;
  done: boolean | null;
  updated_at: any | null;
  created_at: any | null;
}

export interface getCustomMyPlanTask {
  getCustomMyPlanTask: (getCustomMyPlanTask_getCustomMyPlanTask | null)[];
}

export interface getCustomMyPlanTaskVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getLiveSchedule
// ====================================================

export interface getLiveSchedule_getLiveSchedule_data_event_part {
  __typename: "EventPart";
  id: number;
  name: string;
  part: string;
}

export interface getLiveSchedule_getLiveSchedule_data {
  __typename: "LiveSchedule";
  id: any;
  event_uuid: any;
  event_part_uuid: any;
  link: string;
  time: any;
  created_at: any | null;
  updated_at: any | null;
  event_part: getLiveSchedule_getLiveSchedule_data_event_part;
}

export interface getLiveSchedule_getLiveSchedule {
  __typename: "PaginatedLiveSchedule";
  data: getLiveSchedule_getLiveSchedule_data[];
  page: number;
  pageCount: number;
  pageSize: number;
  recordCount: number;
}

export interface getLiveSchedule {
  getLiveSchedule: getLiveSchedule_getLiveSchedule;
}

export interface getLiveScheduleVariables {
  eventUuid: string;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationListByCategory
// ====================================================

export interface invitationListByCategory_invitationListByCategory {
  __typename: "InvitationList";
  id: number;
  first_name: string;
  last_name: string;
  category_id: number | null;
}

export interface invitationListByCategory {
  invitationListByCategory: invitationListByCategory_invitationListByCategory[];
}

export interface invitationListByCategoryVariables {
  eventId: number;
  categoryId?: number | null;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGeneralInviteToken
// ====================================================

export interface getGeneralInviteToken {
  getGeneralInviteToken: string;
}

export interface getGeneralInviteTokenVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCurrencies
// ====================================================

export interface getCurrencies_currencyV1 {
  __typename: "AvailableCurrency";
  /**
   * e.g Dollar, Naira
   */
  name: string;
  /**
   * e.g $, ₦
   */
  symbol: string | null;
  /**
   * e.g USD, NGN
   */
  currency: Currency;
}

export interface getCurrencies {
  /**
   * returns a list of available currencies with thier name, symbol and slug(currency)
   * @isAuthenticated
   */
  currencyV1: (getCurrencies_currencyV1 | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWalletsV1
// ====================================================

export interface getWalletsV1_getWalletsV1_transaction_history_user {
  __typename: "User";
  id: number;
  uuid: any | null;
  email: any | null;
  /**
   * first name of the person
   */
  first_name: string;
  image: string | null;
  /**
   * last name of the person
   */
  last_name: string | null;
  other_name: string | null;
  title: string | null;
  /**
   * gender of the person
   */
  gender: Gender | null;
  /**
   * phone number of the person
   */
  phone_number: string;
  verified: boolean;
  guest: boolean | null;
  created_at: any | null;
  updated_at: any | null;
  lastLoggedInAt: any | null;
}

export interface getWalletsV1_getWalletsV1_transaction_history_metadata {
  __typename: "TransactionMetadata";
  user_id: string | null;
  creditor_alias: string | null;
  item_id: string | null;
  item_name: string | null;
  phone_number: string | null;
  email: string | null;
  bank_account_number: string | null;
  bank_name: string | null;
  account_name: string | null;
  charges: number | null;
  amount_sent: number | null;
  amount: number;
  date: any;
  event_id: number;
  payment_processor_reference: string;
  payment_processor: string;
  transaction_type: string;
  currency: string;
  description: string;
}

export interface getWalletsV1_getWalletsV1_transaction_history {
  __typename: "TransactionV1";
  /**
   * used for indexing
   */
  id: string;
  /**
   * reference -> very important (takes precedence over the id)
   */
  reference: string;
  /**
   * e.g paystack, monnify, stripe, flutterwave ... (all lower case)
   */
  payment_processor: string;
  /**
   * provides context of transaction
   */
  description: string;
  /**
   * narration for the transaction
   */
  narration: string;
  /**
   * the id of the event for which the transaction was made
   */
  event_uuid: any;
  event_id: number;
  /**
   * if the transaction was made by an authenticated user
   */
  user_uuid: any | null;
  /**
   * anonymous or the person's name
   */
  full_name: string | null;
  /**
   * returns if the transaction was made by an authorized user
   */
  user: getWalletsV1_getWalletsV1_transaction_history_user | null;
  /**
   * a uuid string representing the wallet id
   */
  wallet_id: any;
  /**
   * deprecated -> same as virtual bank account number
   */
  wallet_code: string | null;
  /**
   * for now [ USD | NGN ] ...all caps (upper case)
   */
  currency: Currency;
  /**
   * in kobo/cent depending on currency e.g N100 -> 10,000 & $12 -> 1,200
   */
  amount: number;
  /**
   * true|false (a summary of the stage of the transaction) ==>
   *
   * stage:successful-> true
   * &
   * stage:failed -> false
   */
  status: string;
  /**
   * [ successful | failed | pending ] ... (all lower case)
   */
  stage: string;
  /**
   * [ credit | debit ] ... (all lower case)
   */
  type: string;
  /**
   * only provided if the transaction was for payment of a wishlist item
   */
  item_id: string | null;
  metadata: getWalletsV1_getWalletsV1_transaction_history_metadata;
  /**
   * gift, gift-fee, withdraw, withdraw-fee
   */
  kind: string;
  /**
   * time the transaction was done
   */
  created_at: any | null;
  /**
   * last update at
   */
  updated_at: any | null;
}

export interface getWalletsV1_getWalletsV1 {
  __typename: "Wallet";
  /**
   * the uuid that identifies the wallet
   */
  id: string;
  balance: number;
  currency: Currency;
  transaction_history:
    | (getWalletsV1_getWalletsV1_transaction_history | null)[]
    | null;
  pin_is_set: boolean;
  /**
   * is true if the wallet has been frozen
   */
  frozen: boolean;
  /**
   * time when the wallet was created
   */
  created_at: any | null;
  /**
   * last updated at
   */
  updated_at: any | null;
}

export interface getWalletsV1 {
  /**
   * returns all wallet belonging to an event
   */
  getWalletsV1: (getWalletsV1_getWalletsV1 | null)[];
}

export interface getWalletsV1Variables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWalletTransactionHistory
// ====================================================

export interface getWalletTransactionHistory_getWalletTransactionHistoryV1_user {
  __typename: "User";
  id: number;
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  /**
   * phone number of the person
   */
  phone_number: string;
  guest: boolean | null;
}

export interface getWalletTransactionHistory_getWalletTransactionHistoryV1 {
  __typename: "TransactionV1";
  /**
   * used for indexing
   */
  id: string;
  /**
   * provides context of transaction
   */
  description: string;
  /**
   * the id of the event for which the transaction was made
   */
  event_uuid: any;
  /**
   * returns if the transaction was made by an authorized user
   */
  user: getWalletTransactionHistory_getWalletTransactionHistoryV1_user | null;
  /**
   * a uuid string representing the wallet id
   */
  wallet_id: any;
  /**
   * for now [ USD | NGN ] ...all caps (upper case)
   */
  currency: Currency;
  /**
   * in kobo/cent depending on currency e.g N100 -> 10,000 & $12 -> 1,200
   */
  amount: number;
  /**
   * only provided if the transaction was for payment of a wishlist item
   */
  item_id: string | null;
  /**
   * [ credit | debit ] ... (all lower case)
   */
  type: string;
  /**
   * [ successful | failed | pending ] ... (all lower case)
   */
  stage: string;
}

export interface getWalletTransactionHistory {
  /**
   * returns all wallet transaction history.
   * It is paginated and shows the most recent history
   * @isAuthenticated
   */
  getWalletTransactionHistoryV1: getWalletTransactionHistory_getWalletTransactionHistoryV1[];
}

export interface getWalletTransactionHistoryVariables {
  walletId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getForeignAccountDetails
// ====================================================

export interface getForeignAccountDetails_getForeignAccountDetails {
  __typename: "AccountDetails";
  id: string;
  event_id: number;
  walletId: string;
  account_number: string;
  bank_name: string;
  currency: Currency;
  beneficiary_name: string;
  swift_code: string;
  country: string;
  city: string;
  bank_address: string | null;
  post_code: string;
  sort_code: string;
}

export interface getForeignAccountDetails {
  getForeignAccountDetails: (getForeignAccountDetails_getForeignAccountDetails | null)[];
}

export interface getForeignAccountDetailsVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AlbumMediaTag {
  best_man = "best_man",
  bride = "bride",
  bridesmaid = "bridesmaid",
  family = "family",
  friends = "friends",
  groom = "groom",
  hero_page = "hero_page",
  others = "others",
}

export enum BackStoryRole {
  Bride = "Bride",
  Groom = "Groom",
}

export enum Channel {
  email = "email",
  whatsapp = "whatsapp",
}

export enum Currency {
  gpb = "gpb",
  ngn = "ngn",
  usd = "usd",
}

export enum Gender {
  female = "female",
  male = "male",
}

export enum GiftType {
  CashGift = "CashGift",
  WishList = "WishList",
}

export enum RSVPStatus {
  maybe = "maybe",
  no = "no",
  pending = "pending",
  yes = "yes",
}

export enum UserTypeEnum {
  admin = "admin",
  guest = "guest",
}

export enum eventTeamRole {
  bride = "bride",
  groom = "groom",
  planner = "planner",
}

export enum invitationListFilter {
  all = "all",
  not_sent = "not_sent",
  sent = "sent",
}

export enum paymentProcessor {
  flutterwave = "flutterwave",
  monnify = "monnify",
  paystack = "paystack",
  stripe = "stripe",
}

export interface AddEventPartRequest {
  event_id: number;
  time: string;
  name: string;
  welcome_msg?: string | null;
  address: string;
  address_description?: string | null;
  part: string;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  image?: string | null;
  geo_position?: GeoPositionInput | null;
  programs?: (EventPartProgramInput | null)[] | null;
}

export interface AddEventVendorRequest {
  event_id: number;
  name?: string | null;
  role: string;
  email?: string | null;
  phone_number: string;
  instagram_handle?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  image?: string | null;
}

export interface AddToInviteListReq {
  id?: number | null;
  first_name: string;
  last_name: string;
  other_name?: string | null;
  title?: string | null;
  phone: string;
  category_id?: number | null;
  email?: string | null;
  slots?: number | null;
}

export interface CreateStreamer {
  inviteListId: number;
  eventId: number;
  streamer_name: string;
  streamer_email?: string | null;
  streamer_phone: string;
}

export interface EstimateInvitationCostReq {
  pending: boolean;
  all: boolean;
  number_of_guests: number;
  channel: Channel;
}

export interface EventPartProgramInput {
  index: number;
  name: string;
  attr?: any | null;
}

export interface GeoPositionInput {
  geo_position_id?: string | null;
  latitude?: string | null;
  longitude?: string | null;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface MediaFilter {
  album_id?: string | null;
  no_album?: boolean | null;
  tags?: AlbumMediaTag[] | null;
  page?: number | null;
  pageSize?: number | null;
}

export interface SendInviteReq {
  pending: boolean;
  all: boolean;
  channel: Channel;
  list_id: string[];
}

export interface SignUpRequest {
  phone_number: string;
  email: any;
  password: any;
  first_name: string;
  last_name: string;
  other_name?: string | null;
  title?: string | null;
  gender?: Gender | null;
}

export interface TeamMember {
  event_id: number;
  first_name: string;
  last_name: string;
  other_name?: string | null;
  title?: string | null;
  email?: string | null;
  phone_number: string;
  role: eventTeamRole;
}

export interface UpdateEventPartRequest {
  id: number;
  event_id: number;
  time?: string | null;
  name?: string | null;
  part?: string | null;
  welcome_msg?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  image?: string | null;
  geo_position?: GeoPositionInput | null;
  programs?: (EventPartProgramInput | null)[] | null;
  geo_position: {
    geo_position_id: string;
    latitude: string;
    longitude: string;
  };
}

export interface UpdateEventPreferenceRequest {
  id?: number | null;
  event_id: number;
  food?: boolean | null;
  ads?: boolean | null;
  asoebi_selection?: boolean | null;
  gifts?: boolean | null;
  store?: boolean | null;
  vendor_store?: boolean | null;
  reservation?: boolean | null;
  smart_send?: boolean | null;
  dress_code?: boolean | null;
  map?: boolean | null;
  private?: boolean | null;
  live_hall?: boolean | null;
}

export interface UpdateEventVendorRequest {
  id: number;
  event_id: number;
  name?: string | null;
  role?: string | null;
  image?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  email?: string | null;
  instagram_handle?: string | null;
  phone_number?: string | null;
}

export interface UpdateInviteListReq {
  id: number;
  first_name?: string | null;
  last_name?: string | null;
  other_name?: string | null;
  title?: string | null;
  phone?: string | null;
  category_id?: number | null;
  email?: string | null;
  slots?: number | null;
}

export interface UpdateStreamer {
  inviteListId: number;
  eventId: number;
  streamer_name: string;
  streamer_email?: string | null;
  streamer_phone: string;
}

export interface UpdateUserProfileRequest {
  image?: string | null;
  first_name?: string | null;
  other_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
}

export interface addBackStoryReq {
  title?: string | null;
  image?: string | null;
  role: BackStoryRole;
  story: string;
}

export interface foreignAccountDetails {
  event_id: number;
  walletId: string;
  account_number: string;
  bank_name: string;
  currency: Currency;
  beneficiary_name: string;
  swift_code: string;
  country: string;
  city: string;
  bank_address?: string | null;
  post_code: string;
  sort_code: string;
}

export interface payForCashGiftRequest {
  reference: string;
  currency: Currency;
  eventID: number;
  amount?: number | null;
  status?: boolean | null;
  fullName?: string | null;
  phone?: string | null;
  email?: string | null;
  creditorAlias?: string | null;
  paymentProcessor: paymentProcessor;
}

export interface payForWishlistItemRequest {
  currency: Currency;
  itemId: string;
  itemName: string;
  reference: string;
  event_id: number;
  amount: number;
  status: boolean;
  full_name: string;
  phone?: any | null;
  email: any;
  creditorAlias?: string | null;
  paymentProcessor: paymentProcessor;
}

export interface transferFromWalletRequest {
  currency: Currency;
  event_id: number;
  walletId: string;
  amount: number;
  accountNumber: string;
  accountBank: string;
  accountName?: string | null;
  narration?: string | null;
  bankCode: string;
  reference: string;
  walletPin: string;
}

export interface updateBackStoryReq {
  id: number;
  role?: BackStoryRole | null;
  title?: string | null;
  image?: string | null;
  story?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
