import React, { useState } from "react";
import Button from "src/components/Button";
import DeactivateIcon from "src/layouts/dashboard/Icons/DeactivateIcon";
import DialogModal from "src/components/Dialog";
import SelectPassImageModal from "../../Modals/SelectPassImageModal";
import ShareWithBouncerModal from "../../Modals/ShareWithBouncerModal";

const Header = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [shareWithBouncer, setShareWithBouncer] = useState<boolean>(false);
  return (
    <div className="flex justify-between items-center w-full flex-wrap px-5 lg:px-0">
      <h2 className="text-[12px] font-semibold text-[#00000099] tracking-[3px] mb-4 lg:mb-0">
        YOUR INAWO PASS
      </h2>
      <div className="flex lg:gap-[6px] gap-[10px] items-center flex-wrap lg:flex-nowrap">
        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <Button
              label="Set Image"
              type="button"
              design="secondary"
              className="whitespace-nowrap !text-[12px]  sm:!text-[14px]"
              style={{ height: "36px" }}
            >
              Set Image
            </Button>
          }
        >
          <SelectPassImageModal />
        </DialogModal>
        <DialogModal
          open={shareWithBouncer}
          onOpenChange={setShareWithBouncer}
          trigger={
            <Button
              label="Set Image"
              type="button"
              design="secondary"
              className="whitespace-nowrap !text-[12px]  sm:!text-[14px]"
              style={{ height: "36px" }}
            >
              Share with bouncer
            </Button>
          }
        >
          <ShareWithBouncerModal setToggleModal={setShareWithBouncer} />
        </DialogModal>
        <Button
          label="Deactivate"
          type="button"
          design="secondary"
          className="whitespace-nowrap !text-[12px]  sm:!text-[14px]"
          style={{ height: "36px", display: "flex", alignItems: "center" }}
        >
          <div className="flex items-center gap-2">
            <DeactivateIcon />
            <p>Deactivate</p>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default Header;
