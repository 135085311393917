import React from "react";
import Button from "src/components/Button";
import { InawoPassIcon } from "src/layouts/dashboard/Icons/PassDesign";
import { QRCodeCanvas } from "qrcode.react";

const PassCard = () => {
  return (
    <div className="w-[340px] h-[437px] rounded-[7px] flex items-center justify-center flex-col gap-[20px] px-[50px] bg-gradient-to-br from-[#026051] via-[#47A092CC] to-[#3B5F5900]">
      <div
        className="w-[201px] h-[321px] rounded-[21px] py-[9px] px-[15px] border border-[#D9D9D9] flex flex-col items-center justify-between"
        style={{
          backgroundImage: "url(/chivido.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p className="my-[11px] text-[11px] font-medium text-white">
          #TimObim2025
        </p>
        <div
          className="w-full rounded-[22px] bg-[#FFFFFFCC] h-[211px] px-[10px] py-[10px] flex flex-col items-center justify-between"
          style={{ backdropFilter: "blur(3px)" }}
        >
          <div>
            <p className="text-[9px] text-[#4D4D4DE8] mb-[2px]">Guest code</p>
            <p className="text-[10px] text-[#4D4D4D4D]">TWE2110</p>
          </div>
          <div className="w-[105px] h-[105px] border border-[#3D516080] rounded-[20px] bg-gradient-to-b from-[#E6FA5200] to-[#E6FA5233] p-[20px]">
            <QRCodeCanvas
              value="https://reactjs.org/"
              fgColor="#4F7770"
              size={65}
            />
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="mb-[2px] text-[#4D4D4D99] text-[10px] text-center">
              This is your pass to the wedding
            </p>
            <InawoPassIcon />
          </div>
        </div>
      </div>
      <button
        type="button"
        style={{
          background: "transparent",
          border: "1px solid #ffffff",
          borderRadius: "8px",
          width: "85px",
          height: 40,
          fontSize: 12,
          fontWeight: 500,
          color: "#ffffff",
        }}
      >
        Preview
      </button>
    </div>
  );
};

export default PassCard;
