import Icon from "src/components/Icon";
import DialogModal from "src/components/Dialog";
import { Dispatch, SetStateAction, useState } from "react";
import AddSchedule from "../add-schedule";
import Button from "src/components/Button";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useQuery } from "@apollo/client";
import { getLiveSchedule, getLiveScheduleVariables } from "src/types/api";
import { GetLiveSchedules } from "src/graphql/queries/event.queries";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import InnerLoading from "src/components/InnerLoading";
import moment from "moment";
import DeleteSchedule from "../delete-schedule";
import EditLiveSchedule from "../edit-schedule";
import ShareLiveSchedule from "../share-schedule";
import useAppStore from "src/store/utils";
import UpcomingSchedule from "src/assets/icons/NoSchedule";

const LiveSchedules = ({
  addLiveEventDefaultOpen,
  setAddLiveEventDefaultOpen,
}: {
  addLiveEventDefaultOpen: boolean;
  setAddLiveEventDefaultOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [createSchedule, setCreateSchedule] = useState(false);
  const [deleteSchedule, setDeleteSchedule] = useState(false);
  const [editSchedule, setEditSchedule] = useState(false);
  const [shareSchedule, setShareSchedule] = useState(false);

  const navigate = useNavigate();
  const { event } = useAppStore((state) => state);

  const { data, loading } = useQuery<getLiveSchedule, getLiveScheduleVariables>(
    GetLiveSchedules,
    {
      variables: {
        eventUuid: event.uuid,
        page: 1,
        pageSize: 50,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(<p>{error?.message ?? "An error occurred"}</p>);
        } else {
          navigate("/clear");
        }
      },
    }
  );

  if (loading)
    return (
      <div className="h-[30vh]">
        <InnerLoading />
      </div>
    );

  if (!data?.getLiveSchedule.data.length) return <EmptySchedule />;

  return (
    <div className="w-full flex flex-col gap-[3rem]">
      <div className="w-full flex items-center justify-between">
        <h2 className="text-dark-1 text-[1.05rem] font-[600] leading-[1.8rem]">
          Next
        </h2>
        <DialogModal
          trigger={
            <Button
              wrapperBtnClassName="!w-auto !max-w-max"
              className="flex items-center gap-[0.5rem]"
              label="Create Schedule"
              type="button"
            >
              {/* <Icon iconName="calender-icon" /> */}
              <span className="!text-[0.875rem] leading-[1.3125rem] tracking-[-0.0175rem] !text-white">
                Add live event
              </span>
            </Button>
          }
          open={createSchedule}
          onOpenChange={setCreateSchedule}
        >
          <AddSchedule />
        </DialogModal>
      </div>
      <div className="w-full flex flex-col gap-4">
        {data?.getLiveSchedule?.data.map((i, idx) => (
          <div
            key={idx}
            className="w-full flex items-center justify-between py-[0.75rem] px-4 rounded-[0.5rem] bg-secondary-5 shadow-1 border border-secondary-1"
          >
            <div className="flex items-center gap-2.5">
              <div className="w-[68.872px] h-[47.209px] svg-full">
                <Icon iconName="empty-event" />
              </div>
              <div>
                <h4 className="text-black font-[600] leading-[1.5rem] tracking-[-0.01rem] ">
                  {i?.event_part?.name}
                </h4>
                <div className="flex items-center gap-2 text-secondary-7 text-[0.875rem]">
                  <span>{moment(i?.time).format("MMMM Do YYYY")}</span>
                  <Icon iconName="dot-icon" />
                  <span>{moment(i?.time).format("h:mm a")}</span>
                </div>
              </div>
            </div>
            <div className="hidden md:flex items-center gap-[0.75rem]">
              <DialogModal
                trigger={
                  <Tooltip.Provider delayDuration={200}>
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <div className="w-[2.75rem] h-[2.8rem] grid place-items-center bg-white rounded-[0.5rem] border border-secondary-6  cursor-pointer hover:bg-secondary-8 transition-colors ease-in-out duration-300">
                          <Icon iconName="bin" />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Content
                        side="top"
                        className="bg-white px-4 py-1 rounded-sm shadow-lg"
                        sideOffset={4}
                      >
                        <div className=" text-[0.875rem] text-dark-1">
                          Delete
                        </div>
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                }
                open={deleteSchedule}
                onOpenChange={setDeleteSchedule}
              >
                <DeleteSchedule
                  close={() => setDeleteSchedule(false)}
                  schedule={i}
                />
              </DialogModal>

              <DialogModal
                trigger={
                  <Tooltip.Provider delayDuration={200}>
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <div className="w-[2.75rem] h-[2.8rem] grid place-items-center bg-white rounded-[0.5rem] border border-secondary-6  cursor-pointer hover:bg-secondary-8 transition-colors ease-in-out duration-300">
                          <Icon iconName="shareV2" />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Content
                        side="top"
                        className="bg-white px-4 py-1 rounded-sm shadow-lg"
                        sideOffset={4}
                      >
                        <div className=" text-[0.875rem] text-dark-1">
                          Share
                        </div>
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                }
                open={shareSchedule}
                onOpenChange={setShareSchedule}
              >
                <ShareLiveSchedule
                  link={`${process.env.REACT_APP_INAWO_STREAM_BASE_URL}?action=youtube&key=${event.uuid}&schedule=${i?.id}`}
                  scheduleName={i?.event_part?.name}
                />
              </DialogModal>

              <DialogModal
                trigger={
                  <Tooltip.Provider delayDuration={200}>
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <div className="w-[2.75rem] h-[2.8rem] grid place-items-center bg-white rounded-[0.5rem] border border-secondary-6  cursor-pointer hover:bg-secondary-8 transition-colors ease-in-out duration-300">
                          <Icon iconName="pencil" />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Content
                        side="top"
                        className="bg-white px-4 py-1 rounded-sm shadow-lg"
                        sideOffset={4}
                      >
                        <div className=" text-[0.875rem] text-dark-1">Edit</div>
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                }
                open={editSchedule}
                onOpenChange={setEditSchedule}
              >
                <EditLiveSchedule
                  close={() => setEditSchedule(false)}
                  schedule={i}
                />
              </DialogModal>
            </div>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className="md:hidden">
                <div className="w-[2.75rem] h-[2.8rem] grid place-items-center bg-white rounded-[0.5rem] border border-secondary-6  cursor-pointer hover:bg-secondary-8 transition-colors ease-in-out duration-300">
                  <Icon iconName="kebab" />
                </div>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content
                sideOffset={4}
                className="bg-white px-2 py-1 rounded-sm shadow-lg flex flex-col "
              >
                <DialogModal
                  trigger={
                    <DropdownMenu.Item>
                      {" "}
                      <div className=" text-[0.875rem] text-dark-1 px-4 py-2 hover:bg-secondary-6 rounded-[8px]">
                        Share
                      </div>
                    </DropdownMenu.Item>
                  }
                  open={shareSchedule}
                  onOpenChange={setShareSchedule}
                >
                  <ShareLiveSchedule
                    // link={`${process.env.REACT_APP_INAWO_STREAM_BASE_URL}?action=youtube&key=${i?.id}`}
                    link={`${process.env.REACT_APP_INAWO_STREAM_BASE_URL}?action=youtube&key=${event.uuid}&schedule=${i?.id}`}
                    scheduleName={i?.event_part?.name}
                  />
                </DialogModal>
                <DialogModal
                  trigger={
                    <DropdownMenu.Item>
                      {" "}
                      <div className=" text-[0.875rem] text-dark-1 px-4 py-2 hover:bg-secondary-6 rounded-[8px]">
                        Edit
                      </div>
                    </DropdownMenu.Item>
                  }
                  open={editSchedule}
                  onOpenChange={setEditSchedule}
                >
                  <EditLiveSchedule
                    close={() => setEditSchedule(false)}
                    schedule={i}
                  />
                </DialogModal>
                <DialogModal
                  trigger={
                    <DropdownMenu.Item>
                      {" "}
                      <div className=" text-[0.875rem] text-dark-1 px-4 py-2 hover:bg-secondary-6 rounded-[8px]">
                        Delete
                      </div>
                    </DropdownMenu.Item>
                  }
                  open={deleteSchedule}
                  onOpenChange={setDeleteSchedule}
                >
                  <DeleteSchedule
                    close={() => setDeleteSchedule(false)}
                    schedule={i}
                  />
                </DialogModal>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveSchedules;

const EmptySchedule = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="w-full flex flex-col items-center pt-[6rem]">
      <UpcomingSchedule />
      <p className="max-w-[20.4375rem] text-center text-[#707070] text-base leading-[1.8rem] pt-[2.5rem] pb-[1.5rem]">
        You don’t have a live hall event scheduled
      </p>
      <DialogModal
        trigger={
          <div className="!max-w-[10rem] mx-auto">
            <Button
              type="button"
              design="primary"
              label="Add live event"
              className=" !text-white text-[0.875rem]"
            >
              Add live event
            </Button>
          </div>
        }
        open={openModal}
        onOpenChange={setOpenModal}
      >
        <AddSchedule />
      </DialogModal>
    </div>
  );
};
