import Icon from "src/components/Icon";
import styles from "../../photobook.module.scss";
import { trackWindowScroll } from "react-lazy-load-image-component";
import Album from "../Album";
import { photoBookTabTypes, SelectedAlbumType } from "src/utils/types";
import * as Popover from "@radix-ui/react-popover";
import MenuHorizontal from "src/assets/icons/menuHorizontal";
import DialogModal from "src/components/Dialog";
import { v4 as uuidv4 } from "uuid";
import StaticImage from "src/components/StaticImage";
import PlayIcon from "src/assets/icons/play";
import AddPictures from "../AddPictures";
import AddFileIcon from "src/assets/svg/add";
import useCustomMutation from "src/hooks/useCustomMutation";
import toast from "react-hot-toast";
import {
  deleteMedia,
  deleteMediaVariables,
  setHeroImage,
  setHeroImageVariables,
} from "src/types/api";
import { DeleteMedia } from "src/graphql/mutations";
import { SetHeroImage } from "src/graphql/mutations/event.mutation";
import { GetMedia } from "src/graphql/queries";
import useAppStore from "src/store/utils";

type Props = {
  scrollPosition: { x: number; y: number };
  groupedMedia: any[];
  showSelected: boolean;
  selectedAlbum?: SelectedAlbumType;
  setSelectedAlbum?: (album: any) => void;
  setTabState: (tab: photoBookTabTypes) => void;
  selected: string[];
  handleChange: (id: string) => void;
};

const ImageList = ({
  scrollPosition,
  groupedMedia,
  showSelected,
  selected,
  handleChange,
  selectedAlbum,
  setSelectedAlbum,
  setTabState,
}: Props) => {
  const { event } = useAppStore((state) => state);
  const page = 1;
  const pageSize = 200;

  const { mutate: deleteMediaFn } = useCustomMutation<
    deleteMedia,
    deleteMediaVariables
  >(DeleteMedia, {
    onSuccess: () => {
      toast.success(<p className="toast">Successfully deleted!</p>);
    },
  });

  const { mutate: setHeroImageFn } = useCustomMutation<
    setHeroImage,
    setHeroImageVariables
  >(SetHeroImage, {
    onSuccess: () => {
      toast.success(<p className="toast">Successfully set hero image!</p>);
    },
  });

  const handleSetHeroImage = (url: string) => {
    setHeroImageFn({
      variables: {
        image: url,
        setHeroImageEventId2: event.id,
      },
    });
  };

  const handleDelete = (mediaId: string) => {
    deleteMediaFn({
      variables: { eventId: event.uuid, mediaId },
      refetchQueries: [
        {
          query: GetMedia,
          variables: {
            eventId: event.uuid,
            filter: {
              no_album: true,
              page,
              pageSize,
            },
          },
        },
      ],
    });
  };

  return (
    <div className={`${styles["photobook__container"]} pb-8`}>
      {groupedMedia?.map((element: any, index: number) => {
        return (
          <div className={styles["photobook__card__container"]} key={uuidv4()}>
            {showSelected && !element.isAlbum && (
              <div className={styles["photobook__card__checkbox"]}>
                <input
                  type="checkbox"
                  id={`checkbox-${element.id}`}
                  checked={selected.includes(element.id)}
                  onChange={() => handleChange(element.id)}
                />
                <label htmlFor={`checkbox-${element.id}`}></label>
              </div>
            )}
            {element?.type?.includes("image/") ? (
              <div className={styles["photobook__card__picture"]}>
                <Icon iconName="picture" />
              </div>
            ) : null}

            {element.isAlbum ? (
              <Album
                element={element}
                setSelectedAlbum={(album) => {
                  setSelectedAlbum?.({
                    id: album?.id,
                    name: album!.name,
                  });
                  setTabState("albums");
                }}
              />
            ) : (
              <div
                className={`${styles["photobook__card"]} !h-[260px] max-h-full relative !overflow-hidden w-full`}
              >
                {!element.isAlbum && (
                  <div className={styles["photobook__card__delete"]}>
                    <Popover.Root>
                      <Popover.Trigger
                        className={
                          styles["photobook__dropdown-container-button"]
                        }
                      >
                        <MenuHorizontal />
                        <span className="visually-hidden">open dropdown</span>
                      </Popover.Trigger>
                      <Popover.Content
                        className={styles["photobook__dropdown"]}
                      >
                        <div className={styles["photobook__dropdown-list"]}>
                          <div
                            onClick={() => handleSetHeroImage(element.url)}
                            className={styles["photobook__dropdown-list__btn"]}
                          >
                            Set as hero image
                          </div>
                          <div
                            onClick={() => handleSetHeroImage(element.url)}
                            className={
                              styles["eventGallery__dropdown-list__remove"]
                            }
                          >
                            Set as pass image
                          </div>
                          <div
                            onClick={() => handleDelete(element.id)}
                            className={
                              styles["eventGallery__dropdown-list__remove"]
                            }
                          >
                            Delete
                          </div>
                        </div>
                      </Popover.Content>
                    </Popover.Root>
                  </div>
                )}
                <DialogModal
                  key={uuidv4()}
                  disabled={showSelected}
                  className="!w-full"
                  trigger={
                    <>
                      {element?.type?.includes("video/") ? (
                        <>
                          <video
                            className="!object-cover h-[260px] w-[260px] rounded-[5.48px] inline-block"
                            controls={false}
                          >
                            <source src={element?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center bg-black/30 rounded-[5.48px]">
                            <PlayIcon />
                          </div>
                        </>
                      ) : (
                        <label htmlFor={`checkbox-${element.id}`}>
                          <StaticImage
                            src={element?.url}
                            alt={`event-${index + 1}`}
                            imageClassName="!object-cover rounded-[5.48px]"
                            className="!w-full !h-full"
                            scrollPosition={scrollPosition}
                          />

                          <img
                            src={element?.url}
                            alt={`mage`}
                            className="sm:w-[260px] h-[184px] object-cover rounded-[5.48px]"
                          />
                        </label>
                      )}
                    </>
                  }
                >
                  <div className="h-[591px]  rounded-md overflow-hidden backdrop-blur-3xl bg-[white]/30">
                    {element?.type?.includes("video/") ? (
                      <video
                        preload="none"
                        className="h-full w-full object-cover rounded-md transition-all duration-300"
                        controls
                        autoPlay
                      >
                        <source src={element?.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <StaticImage
                        src={element?.url}
                        alt={`img-${index + 1}`}
                        imageClassName="!object-cover"
                        className="!w-auto h-full items-center justify-center !flex "
                      />
                    )}
                  </div>
                </DialogModal>
              </div>
            )}
          </div>
        );
      })}
      {selectedAlbum && (
        <AddPictures
          trigger={
            <div className="min-h-[160px] w-full h-[260px] sm:w-[260px] flex flex-col justify-center items-center border border-dashed border-[#616161] rounded-[6px] px-[22px] sm:px-[35px] cursor-pointer">
              <AddFileIcon />
              <p className="text-[#919191] font-medium text-[8px] sm:text-[11px] leading-[9px] sm:leading-[13px] text-center mt-[14px] sm:mt-[23px]">
                ADD A NEW PHOTO/VIDEO
              </p>
              <p className="text-[#898989] text-[10px] sm:text-[12px] leading-[12px] sm:leading-[18px] text-center font-normal mt-[8px] sm:mt-[13px]">
                Organize your people and guests, and start treating them as
                groups e.g brides family
              </p>
            </div>
          }
          album={selectedAlbum}
          type="photo"
        />
      )}
    </div>
  );
};

export default trackWindowScroll(ImageList);
