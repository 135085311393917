import { useState } from "react";
import AddPart from "./modals/AddPart";
import styles from "./styles.module.scss";
import { EventPartCard } from "src/components/Cards";
import { useQuery } from "@apollo/client";
import { EventParts } from "src/graphql/queries";
import InnerLoading from "src/components/InnerLoading";
// import Pagination from "src/components/Pagination";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import EmptyState from "./EmptyState";
import { eventParts } from "src/types/api";
import DialogModal from "src/components/Dialog";
import { useNavigate } from "react-router-dom";
import useAppStore from "src/store/utils";
import DashboardLayout from "src/layouts/dashboard";

function EventSchedule() {
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  // const [totalPages, setTotalPages] = useState(1);
  // const [startPage, setStartPage] = useState(1);
  // const [pageSize, setPageSize] = useState(50);
  // const [page, setPage] = useState(1);
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const { data: parts, loading: eventPartLoading } = useQuery<eventParts>(
    EventParts,
    {
      variables: {
        eventId: event.id,
        page: 1,
        pageSize: 50,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(<p>{error?.message ?? "An error occurred"}</p>);
        } else {
          navigate("/clear");
        }
      },
    }
  );

  return (
    <DashboardLayout pageName="Event Schedule">
      <div className={styles["MyParts"]}>
        <header className={styles["MyParts__header"]}>
          {!eventPartLoading && !!(parts?.eventParts?.length ?? 0 < 1) && (
            <DialogModal
              open={openAdd}
              onOpenChange={setOpenAdd}
              trigger={
                <div
                  onClick={() => setOpenAdd(true)}
                  className={styles["MyParts__header__trigger"]}
                >
                  <span>Add Part</span>
                </div>
              }
            >
              <AddPart setOpen={setOpenAdd} />
            </DialogModal>
          )}
        </header>
        {eventPartLoading || !parts?.eventParts ? (
          <div className={styles["MyParts__loading"]}>
            <InnerLoading />
          </div>
        ) : !!(parts?.eventParts.length === 0 || undefined || null) ? (
          <div className={styles["MyParts"]}>
            <EmptyState />
          </div>
        ) : (
          <main className={styles["MyParts__main"]}>
            {parts?.eventParts
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(a.time).getTime() - new Date(b.time).getTime()
              )
              .map((data) => {
                return <EventPartCard part={data} key={uuidv4()} />;
              })}
          </main>
        )}
        {/* {!eventPartLoading && !!(parts?.eventParts?.length ?? 0 < 1) && (
        <Pagination
          pageSize={pageSize}
          startPage={startPage}
          setPageSize={setPageSize}
          setStartPage={setStartPage}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
      )} */}
      </div>
    </DashboardLayout>
  );
}

export default EventSchedule;
