import styles from "./styles.module.scss";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "src/components/Input";
import Button from "src/components/Button";
import { AddEventPart } from "src/graphql/mutations";
import { useMutation } from "@apollo/client";
import { EventParts } from "src/graphql/queries";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { addEventPart, addEventPartVariables } from "src/types/api";
import { useNavigate } from "react-router-dom";
import useAppStore from "src/store/utils";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

interface Values {
  name: string;
  location: string;
  date: string;
}

const AddPartSchema = Yup.object().shape({
  name: Yup.string().required("Part name is required"),
  location: Yup.string().required("Please input your event location"),
  date: Yup.date()
    .min(new Date().toISOString(), "You cannot choose a past date")
    .required("Date is required"),
});

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function AddPart({ setOpen }: Props) {
  const { event } = useAppStore((state) => state);

  const navigate = useNavigate();
  const [addEventPart, { loading: addEventPartLoading }] = useMutation<
    addEventPart,
    addEventPartVariables
  >(AddEventPart, {
    onCompleted() {
      toast.success(<p className="toast">Added a New Part</p>);
      setOpen(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["AddPart"]}>
      <h1>Add Part</h1>
      <Formik
        initialValues={{
          name: "",
          location: "",
          date: "",
        }}
        validationSchema={AddPartSchema}
        onSubmit={(values, actions) => {
          addEventPart({
            variables: {
              input: {
                event_id: event.id,
                time: `${+new Date(values.date) / 1000}`,
                name: values.name,
                part: values.name,
                address: values.location,
              },
            },
            refetchQueries: [
              {
                query: EventParts,
                variables: { eventId: event.id },
              },
            ],
          });
          // actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["AddPart-form"]}>
            <div className={styles["AddPart-form_container"]}>
              <Input
                type="text"
                name="name"
                label="Part Name"
                placeholder="e.g Mike and Ife's Engagement"
              />
              <Input
                type="text"
                name="location"
                label="Location"
                placeholder="Enter Location"
              />
              <Input
                type="datetime-local"
                name="date"
                label="Date"
                placeholder="Enter Date"
                min={new Date().toISOString()}
              />
            </div>
            <div className={styles["AddPart-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Add Part"
                  disabled={
                    props.values.name === "" ||
                    props.values.location === "" ||
                    props.values.date === "" ||
                    !props.isValid ||
                    props.isSubmitting
                  }
                  loader={addEventPartLoading}
                >
                  Add Part
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddPart;
