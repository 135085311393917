import React, { useState } from "react";
import Button from "src/components/Button";
import axios from "axios";
import InnerLoading from "src/components/InnerLoading";
import NoInviteList from "src/assets/icons/NoInviteList";

interface ContactModalProps {
  setOpenContactsModal: (open: boolean) => void;
  onAddContacts: (contacts: any) => void;
  contacts: any[];
  loading: boolean;
  refetch?: () => void;
}

interface SingleContactItemProps {
  selectedContacts: any;
  contact: any;
  handleToggleContact: (contact: any) => void;
}

export const fetchContacts = async (accessToken: string) => {
  const API_URL = "https://people.googleapis.com/v1/people/me/connections";
  try {
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        personFields: "names,emailAddresses,phoneNumbers",
      },
    });
    // Format the response to include only relevant data
    const contacts =
      response.data.connections?.map((contact: any) => ({
        firstName: contact.names?.[0]?.givenName || "",
        lastName: contact.names?.[0]?.familyName || "",
        email: contact.emailAddresses?.[0]?.value || "",
        phone: contact.phoneNumbers?.[0]?.value || "",
      })) || [];
    return contacts;
  } catch (error) {
    console.error("Error fetching contacts:", error);
    return [];
  }
};

const SingleContactItem = ({
  selectedContacts,
  contact,
  handleToggleContact,
}: SingleContactItemProps) => {
  return (
    <div className="flex items-center gap-4">
      <input
        type="checkbox"
        style={{ transform: "scale(1.5)" }}
        checked={selectedContacts.some(
          (c: any) => c.firstName === contact.firstName
        )}
        onChange={() => handleToggleContact(contact)}
      />
      <div className="flex items-center gap-4">
        <div className="w-[40px] h-[40px] bg-gray-300 rounded-full flex items-center justify-center">
          <p className="font-bold text-[18px]">{`${contact?.firstName.substr(
            0,
            1
          )}${contact?.lastName.substr(0, 1)}`}</p>
        </div>
        <div className="flex flex-col">
          <h3 className="font-bold text-[16px]">{`${contact?.firstName} ${contact?.lastName}`}</h3>
          <p className="text-[14px]">{contact?.email}</p>
        </div>
      </div>
    </div>
  );
};

const ImportContactModal = ({
  setOpenContactsModal,
  onAddContacts,
  contacts,
  loading,
  refetch,
}: ContactModalProps) => {
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);

  const handleToggleContact = (contact: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: number;
  }) => {
    setSelectedContacts((prev) =>
      prev.some((c) => c.firstName === contact.firstName)
        ? prev.filter((c) => c.firstName !== contact.firstName)
        : [...prev, contact]
    );
  };

  const handleAddContacts = () => {
    onAddContacts(selectedContacts);
    setOpenContactsModal(false);
  };

  return (
    <div
      className="w-[500px] h-[500px] bg-white flex flex-col gap-5 p-[30px]"
      style={{ borderRadius: "12px" }}
    >
      {!loading && contacts?.length === 0 ? (
        <div className="px-8 flex flex-col items-center justify-center h-full">
          <div className="mb-7">
            <NoInviteList />
          </div>
          <p className="text-center mb-5 font-medium">
            Oops! can't get your contacts at the moment
          </p>
          <Button label="retry" type="button" handleClick={refetch}>
            Retry
          </Button>
        </div>
      ) : (
        <div className="px-7 px-5">
          <h2 className="font-bold text-[18px]">Select Contacts</h2>
          <div
            className="flex flex-col gap-5 px-3"
            style={{ overflowY: "auto", height: "430px" }}
          >
            {loading ? (
              <div className="mt-5">
                <InnerLoading />
              </div>
            ) : (
              <>
                {contacts.map((item: any, i: any) => (
                  <SingleContactItem
                    key={i}
                    contact={item}
                    selectedContacts={selectedContacts}
                    handleToggleContact={handleToggleContact}
                  />
                ))}
              </>
            )}
          </div>
          <Button
            label="Send invites"
            type="button"
            design="primary"
            className=" flex items-center gap-[0.7rem] !text-white"
            handleClick={handleAddContacts}
          >
            Add Contacts
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImportContactModal;
