import { useState } from "react";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import Info from "src/components/Info";
import AddPart from "../modals/AddPart";
import styles from "./styles.module.scss";
import { EventSchedule as EventScheduleIcon } from "../../../layouts/dashboard/Icons";

function EmptyState() {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles["EmptyState"]}>
      <Info>
        {/* <Icon iconName="bulb" /> */}
        <p className={styles["EmptyState--title"]}>
          Add the different parts of your event schedule here
        </p>
      </Info>

      <div className={styles["EmptyState--main"]}>
        {/* <Icon iconName="parts" /> */}
        <div className="lg:mb-[70px] mb-[30px]">
          <EventScheduleIcon />
        </div>
        <p>
          Engagement, reception, after-party etc. Add the different parts of
          your event
        </p>
        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <div
              onClick={() => setOpen(true)}
              className={styles["EmptyState--main__trigger"]}
            >
              <span>Add event part</span>
            </div>
          }
        >
          <AddPart setOpen={setOpen} />
        </DialogModal>
      </div>
    </div>
  );
}

export default EmptyState;
