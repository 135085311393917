import moment from "moment/moment";
import toast from "react-hot-toast";

export const phoneRegExp =
  /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

export function getUtcOffset(date: Date) {
  return moment(date).add(moment(date).utcOffset(), "minutes").utc();
}

export function formatDate(inputDate: string) {
  const date = new Date(inputDate);
  const options = {
    weekday: "long" as "long",
    month: "short" as "short",
    day: "2-digit" as "2-digit",
    year: "numeric" as "numeric",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export function indexOfEarliestDate(arr: Date[]): number {
  if (arr.length === 0) {
    return -1;
  }

  var earliest = arr[0];
  var earliestIndex = 0;

  for (var i = 1; i < arr.length; i++) {
    if (moment(arr[i]).isBefore(earliest)) {
      earliestIndex = i;
      earliest = arr[i];
    }
  }

  return earliestIndex;
}

// a more concise version of the initial
export const indexOfEarliestDate2 = (arr: Date[]): number =>
  arr.reduce(
    (bestIndexSoFar, currentlyTestedValue, currentlyTestedIndex, array) =>
      moment(currentlyTestedValue).isBefore(array[bestIndexSoFar])
        ? currentlyTestedIndex
        : bestIndexSoFar,
    0
  );

export const copyToClipboard = (text: string, alert: any) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success(alert);
    })
    .catch(() => {
      toast.error(`An Error occurred while copying`);
    });
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber.charAt(4) === "0" && phoneNumber.slice(1, 4) === "234") {
    return `+234${phoneNumber.slice(5)}`;
  } else {
    return `${phoneNumber}`;
  }
};
