import { useQuery } from "@apollo/client";
import { useState, useContext, SetStateAction, Dispatch } from "react";
import {
  InvitationListByRSVP,
  NumberOfGuestsByCategory,
} from "src/graphql/queries";
import styles from "./coming.module.scss";
// import Pagination from "src/components/Pagination";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import EmptyState from "../EmptyState";
import { v4 as uuidv4 } from "uuid";
import { SearchInput } from "src/components/Input";
import Icon from "src/components/Icon";
import toast from "react-hot-toast";
import { invitationListByRSVPStatus, noGuestPerCategory } from "src/types/api";
import Row from "./Row";
import { useNavigate } from "react-router-dom";
import { SectionRsvpCountType } from "src/utils/types";
import useAppStore from "src/store/utils";
import useCustomQuery from "src/hooks/useCustomQuery";

type Props = {
  count: SectionRsvpCountType;
  setCount: Dispatch<SetStateAction<SectionRsvpCountType>>;
};

const Coming: React.FC<Props> = (props) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [startPage, setStartPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const { event } = useAppStore((state) => state);
  const navigate = useNavigate();

  const { data: categories } = useCustomQuery<noGuestPerCategory>(
    NumberOfGuestsByCategory,
    { eventId: event.id },
    !event.id
  );

  const { data: coming, isLoading: comingLoading } =
    useCustomQuery<invitationListByRSVPStatus>(
      InvitationListByRSVP,
      {
        eventId: event.id,
        rsvpStatus: "yes",
        page: page,
        pageSize: pageSize,
      },
      !event.id
    );

  const results = !!coming
    ? coming.invitationListByRSVPStatus?.filter(
        (guest) =>
          guest.first_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          guest.last_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
      )
    : [];

  return (
    <>
      <div className={styles["coming"]}>
        {comingLoading ? (
          <div className={styles["coming__loading"]}>
            <InnerLoading />
          </div>
        ) : !!coming && !!coming.invitationListByRSVPStatus ? (
          <>
            <div className={styles["search"]}>
              <div className={styles["search__inner"]}>
                <SearchInput
                  icon={<Icon iconName="search" />}
                  name="Search names, emails etc."
                  placeholder="Search names, emails etc."
                  value={searchValue}
                  onChange={(e: {
                    target: { value: SetStateAction<string> };
                  }) => setSearchValue(e.target.value)}
                />
              </div>
              <div className={styles["count"]}>
                <Icon iconName="twoUsers" />{" "}
                {coming.invitationListByRSVPStatus.length}
              </div>
            </div>
            <main className={styles["coming__main"]}>
              <div className={styles["coming__table"]}>
                <div className={styles["coming__table-header"]}>
                  <h4>NAME</h4>
                  <h4>EMAIL</h4>
                  <h4>WHATSAPP NUMBER</h4>
                  <h4>CATEGORY</h4>
                  <h4>&nbsp;</h4>
                </div>
                <div className={styles["coming__table-list"]}>
                  {results?.map((data) => (
                    <>
                      <Row
                        key={uuidv4()}
                        id={data?.id}
                        firstName={data?.first_name}
                        lastName={data?.last_name}
                        phoneNumber={data?.phone}
                        email={data?.email ?? ""}
                        category={data?.category?.name ?? ""}
                      />
                    </>
                  ))}
                </div>
              </div>
            </main>
          </>
        ) : (
          <EmptyState categories={categories} page={page} pageSize={pageSize} />
        )}

        {/*!!coming && !!coming.invitationListByRSVPStatus && (
          <Pagination
            pageSize={pageSize}
            startPage={startPage}
            setPageSize={setPageSize}
            setStartPage={setStartPage}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
          />
						)*/}
      </div>
    </>
  );
};

export default Coming;
