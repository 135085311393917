import React from "react";

const DeactivateIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.23057"
        y="1.0123"
        width="16.2"
        height="16.2"
        rx="3.56218"
        stroke="black"
        stroke-opacity="0.4"
        stroke-width="0.8"
      />
      <rect
        x="4.83057"
        y="4.6123"
        width="9"
        height="9"
        rx="2.14774"
        fill="#4F4F4F"
      />
    </svg>
  );
};

export default DeactivateIcon;
